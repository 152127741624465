<template>
	<vue-tags-input :placeholder="placeholder" class="w-100"
					:tags="tags"
					v-model="tag"
					:disabled="disabled"
					@tags-changed="updateTags"

	/>
</template>

<script>
	import VueTagsInput from '@johmun/vue-tags-input';

	export default {
		props: {
			value: {
				type: Array,
				default: () => []
			},
			placeholder: {
				type: String,
				default: ''
			},
			disabled: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				tag: "",
			}
		},
		methods: {
			updateTags(newTags) {
				this.tags = newTags;
			}
		},
		computed: {
			tags: {
				get() {
					return this.value.map(t => {
						return {
							text: t
						}
					})
				},
				set(tags) {
					tags.sort((a, b) => parseInt(a.text) > parseInt(b.text) ? 1 : -1)
					this.$emit('input', tags.map(t => t.text))
				}
			}
		},
		components: {
			VueTagsInput
		}
	}
</script>
