<template>
	<div class="d-flex align-items-center">
		<symbol-switcher :disabled="disabled" v-model="symbol" :config="config" :enable-null="true"></symbol-switcher>
		<div v-if="model">
			<h5 class="text-center">Block size</h5>
			<div class="d-flex">
				<div class="text-center">
					<div class="text-small">Width</div>
					<multiplier :disabled="disabled" :min="1" :max="100" v-model="model.width"></multiplier>
				</div>
				<div class="text-center">
					<div class="text-small">Height</div>
					<multiplier :disabled="disabled" :min="1" :max="100" v-model="model.height"></multiplier>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import SymbolSwitcher from "../symbols/symbol-switcher";
	import Multiplier from "../prize/multiplier";

	export default {
		name: "megasymbol",
		components: {Multiplier, SymbolSwitcher},
		props: {
			config: {
				type: Object
			},
			value: {
				type: Object
			},
			disabled: {
				default: false,
				type: Boolean
			}
		},
		mounted() {
			if (this.model) {
				this.size = {
					width: this.model.width,
					height: this.model.height
				}
			}
		},
		computed: {
			symbol: {
				get() {
					return this.model ? this.model.symbol : null
				},
				set(_val) {

					if (_val) {
						if (this.model === null) {
							this.model = {
								width: 1,
								height: 1,
								symbol: _val
							}
						} else {
							this.model.symbol = _val
						}
					} else {
						this.model = null
					}

				}
			},
			model: {
				get() {
					return this.value
				},
				set(_val) {
					this.$emit("input", _val)
				}
			},
		}
	}
</script>

<style scoped>

</style>
