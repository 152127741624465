<template>
	<div class="modal-backdrop position-fixed" @click="close">
		<div class="modal-dialog" role="document" @click.stop>
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">
						<slot name="title"></slot>
					</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<slot></slot>
				</div>
				<div class="modal-footer">
					<slot name="footer">
						<button type="button" class="btn btn-primary" @click="save">Save changes</button>
					</slot>
					<button type="button" class="btn btn-danger" data-dismiss="modal" @click="close">Close</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "simple-modal",
	methods: {
		close() {
			this.$emit("onCancel")
			this.$emit("close")
		},
		save() {
			this.$emit("onSave")
		}
	}
}
</script>

<style scoped>

</style>
