<template>
	<div>
		<div v-if="model.length > 0">
			<div v-for="(condition, index) in model" class="d-flex my-2 border p-2 align-items-center">
				<div :disabled="disabled" :config="config" :is="condition.type"
					 v-model="model[index]" class="flex-grow-1"></div>
				<div v-if="!disabled">
					<button class="btn btn-danger btn-sm text-small" @click="removeCondition(index)">Remove condition
					</button>
				</div>
				<hr>
			</div>
		</div>

		<div class="text-muted text-small" v-else>No conditions</div>

		<button class="btn btn-primary text-small btn-sm" @click="dialogOpen = true" v-if="!disabled">
			Add condition <i class="fa fa-plus"></i>
		</button>

		<simple-modal v-if="dialogOpen" v-on:onCancel="dialogOpen = false">
			<div slot="title">Chose one condition to add</div>

			<div v-for="condition in conditionsList" class="my-2">
				<button class="btn btn-primary text-small btn-sm" @click="addCondition(condition)">{{condition.name}}
				</button>
			</div>

			<div slot="footer"></div>
		</simple-modal>
	</div>
</template>

<script>
	import SimpleModal from "../../../layout/simple-modal";
	import FreeRespins from './editor/free-respins'
	import Respins from './editor/respins'
	import UntilSymbol from './editor/until-symbol'
	import UntilSymbolEnd from './editor/until-symbol'
	import UntilWinning from './editor/until-winning'

	import Reel from './editor/reel'
	import Row from './editor/row'
	import InARow from './editor/in-a-row'
	import GameType from './editor/game-type'
	import GameTypeEnd from './editor/game-type'
	import NumberOfSymbols from './editor/number-of-symbols'

	export default {
		name: "conditions",
		components: {
			SimpleModal,
			FreeRespins,
			Respins,
			UntilSymbol,
			UntilSymbolEnd,
			UntilWinning,
			Reel,
			Row,
			GameType,
			GameTypeEnd,
			NumberOfSymbols,
			InARow
		},
		props: {
			value: {
				type: Array,
				default: () => []
			},
			conditionsList: {
				type: Array,
				default: () => []
			},
			config: {
				type: Object
			},
      disabled: {
        default: false,
        type: Boolean
      }
    },
		data: () => ({
			dialogOpen: false
		}),
		methods: {
			addCondition(condition) {
				this.dialogOpen = false
				this.model.push({
					type: condition.type,
					rules: condition.default()
				})
			},
			removeCondition(index) {
				this.model.splice(index, 1)
			}
		},
		computed: {
			model: {
				get() {
					return this.value
				},
				set(value) {
					this.$emit("input", value)
				}
			},
		}
	}
</script>

<style scoped>

</style>
