export default [
	{
		type: 'respins',
		name: 'Number of respins',
		default: () => ({
			respins: 1
		})
	},
	{
		type: 'until-winning',
		name: 'Until player hits a winning hand',
		default: () => ({
			wins: 1
		})
	},
	{
		type: 'until-symbol-end',
		name: 'Until a symbol is shown',
		default: () => ({
			symbols: ["1"]
		})
	},
	{
		type: 'game-type-end',
		name: 'Type of spin',
		default: () => ({
			type: 'freespin'
		})
	},
]
