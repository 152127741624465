<template>
	<div class="block-note" :class="{open}">
		<div class="header cursor-pointer" @click="toggleOpen">
			<div class="row">
				<div class="col-auto">PROJECT NOTES</div>
				<div class="col"></div>
				<div class="col-auto">
					<i class="fa fa-dot-circle" :class="{'text-success': synced, 'text-light': !synced}"></i>
				</div>
			</div>
		</div>
		<ckeditor :disabled="disabled" class="editor" :config="editorConfig" v-if="open" :editor="editor" v-model="note"></ckeditor>
	</div>
</template>

<script>
	import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


	export default {
		props: {
			value: {
				type: String,
				default: ""
			},
			synced: {
				type: Boolean,
				default: false
			},
			disabled: {
				default: false,
				type: Boolean
			}
		},
		data() {
			return {
				editor: ClassicEditor,
				editorConfig: {
					toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'insertTable'],
				},
				open: false
			}
		},
		mounted() {

		},
		methods: {
			toggleOpen() {
				this.open = !this.open
			}
		},
		computed: {
			note: {
				get() {
					return this.value
				},
				set(notes) {
					this.$emit('input', notes)
				}
			},
		}
	}
</script>

<style scoped lang="scss">
	@import "@/assets/style/_palette";

	.block-note {
		position: fixed;
		bottom: 0;
		right: 0;
		display: block;
		background: white;

		.header {
			padding: 8px;
			border: 1px solid $color-grey;
			border-bottom: 0;
			min-width: 300px;
		}

		&.open {
			position: fixed;
			right: 0;
			bottom: 0;
			width: 50%;

			@media (max-width: 768px){
				width: 80vw;
			}

			box-shadow: 0 0 10px -3px rgba(0, 0, 0, 0.3);
		}


	}
</style>

<style>
	.ck-content {
		min-height: 80vh;
	}
</style>
