<template>
	<div>
		<div class="row">
			<div class="col text-center">
				<div class="text-nowrap text-small font-weight-bold">First symbol</div>
				<symbol-switcher :config="config" :disabled="disabled" class="d-inline-flex" v-model="options.first"></symbol-switcher>
			</div>
			<div class="col text-center">
				<div class="text-nowrap text-small font-weight-bold">Second symbol</div>
				<symbol-switcher :config="config" :disabled="disabled" class="d-inline-flex" v-model="options.second"></symbol-switcher>
			</div>
			<div class="col text-center">
				<div class="text-nowrap text-small font-weight-bold">Offset to second symbol</div>
				<offset :config="config" :disabled="disabled" class="d-inline-block" :symbols="[options.first, options.second]" v-model="options.offset"></offset>
			</div>
			<div class="col text-center">
				<div class="text-nowrap text-small font-weight-bold">Winning symbol offset</div>
				<offset :config="config" :disabled="disabled" class="d-inline-block" :symbols="[options.first, '?']" v-model="options.winning_offset"></offset>
			</div>
			<div class="col text-center">
				<div class="text-nowrap text-small font-weight-bold">Winning symbol multiplier</div>
				<div class="d-inline-flex">
					<multiplier :disabled="disabled" v-model="options.winning_symbol_multiplier" :min="0"></multiplier>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import SymbolSwitcher from "../symbols/symbol-switcher";
	import Offset from "./offset";
	import Multiplier from "../prize/multiplier";

	export default {
		components: {Multiplier, Offset, SymbolSwitcher},
		props: {
			value: {
				type: Object
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			config: {
				type: Object
			}
		},
		computed: {
			options: {
				get() {
					return this.value
				},
				set(options) {
					this.$emit("input", options)
				}
			}
		}
	}
</script>
