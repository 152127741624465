<template>
	<div>
		<reel-importer :disabled="disabled" v-model="importedReel" :accept-nulls="true" :config="config"></reel-importer>

		<div class="p-2 border my-3">
			<div class="text-small">Offset reels starting from left</div>
			<div class="text-small text-light">An offset of 0 means the first reel</div>
			<multiplier :disabled="disabled" v-model="model.offset" :min="0" :max="config.reels.length - 1"></multiplier>
		</div>

		<div class="alert alert-success" v-if="everyReelsSameLength">
			Every real reels (reel {{model.offset + 1}} to {{symbolMaxWidth + 1}}) involved in megasymbol inside this overlay reel are of the same length.
		</div>
		<div class="alert alert-danger" v-else>
			Not every real reels (reel {{model.offset + 1}} to {{symbolMaxWidth + 1}}) involved in megasymbol inside this overlay reel are not of the same length.<br/>
			Symbol exceding viewport will be discarded.<br/>
			If every reels involved as the same length, symbol will pop from beginning of the list.
		</div>

		<table class="table">
			<tr v-for="(symbol, index) in model.symbols">
				<td>
					<megasymbol :disabled="disabled" v-model="model.symbols[index]" :config="config"></megasymbol>
				</td>
				<td v-if="!disabled">
					<button tabIndex="-1" class="btn" @click="addSymbol(index)">
						<i class="fa fa-plus text-light"></i>
					</button>
					<button tabIndex="-1" class="btn" @click="removeSymbol(index)">
						<i class="fa fa-trash text-light"></i>
					</button>
				</td>
			</tr>
		</table>

		<button tabIndex="-1" class="btn" @click="addSymbol(model.symbols.length)" v-if="!disabled">
			<i class="fa fa-plus text-light"></i>
		</button>

	</div>
</template>

<script>
	import Megasymbol from "./megasymbol";
	import ReelImporter from "./reel-importer";
	import Multiplier from "../prize/multiplier";

	export default {
		name: "overlay-reel",
		components: {Multiplier, ReelImporter, Megasymbol},
		props: {
			value: {
				type: Object,
				default: () => []
			},
			config: {
				type: Object
			},
			disabled: {
				default: false,
				type: Boolean
			}
		},
		methods: {
			addSymbol(index) {
				this.model.symbols.splice(index, 0, null)
			},
			removeSymbol(index) {
				this.model.symbols.splice(index, 1)
			}
		},
		computed: {
			everyReelsSameLength() {
				return this.involvedReels.every((currentValue) => currentValue.length === this.involvedReels[0].length)
			},
			involvedReels(){
				return this.config.reels.slice(this.model.offset, this.symbolMaxWidth)
			},
			symbolMaxWidth() {
				return this.model.symbols.filter(s => s).reduce((c, s) => Math.max(s.width, c), 0)
			},
			model: {
				get() {
					return this.value
				},
				set(_val) {
					this.$emit("input", _val)
				}
			},
			symbols() {
				return this.config.symbols
			},
			importedReel: {
				set(_val) {
					console.log('importedReel', _val)

					let tmp = _val.map(s => {
						if (s) {
							return {
								width: 1,
								height: 1,
								symbol: s
							}
						} else {
							return null
						}
					})

					this.model.symbols = tmp

				},
				get() {
					return []
				}
			}
		}
	}
</script>

<style scoped>

</style>
