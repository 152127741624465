<template>
	<div class="my-5">
		<div class="d-flex justify-content-center py-5" v-if="loading">
			<div class="spinner-border" role="status">
				<span class="sr-only">Loading...</span>
			</div>
		</div>
		<template v-else>
			<prevent-unload :when="modified"></prevent-unload>
			<div class="row" style="z-index: 10">
				<div class="col">
					<nav class="navbar-nav">
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb">
								<li class="breadcrumb-item">
									<router-link :to="{name: 'projects-index'}">Projects</router-link>
								</li>

								<li class="breadcrumb-item">
									<router-link :to="{
								name: 'projects-editor-uuid-index',
								params: {
									uuid: project.Uuid
								}
							}" v-text="`${project.Code} - ${project.Name}`"></router-link>
								</li>
								<li class="breadcrumb-item active" aria-current="page">
									<span class="font-weight-bold" v-text="config.Name"></span>
									<span class="text-small ml-2" v-text="`v. ${config.Version}`"></span>
								</li>

								<li class="breadcrumb-item active">
									Configuration
								</li>
							</ol>
						</nav>

					</nav>
				</div>
			</div>

			<div class="d-flex flex-column" v-if="config">
				<project-navbar class="flex-grow-1 mb-3" :project="project" :project-config="config">
					<div v-if="!editingDisabled">
						<div class="d-inline-block p-2" v-if="modified">
							<button class="btn btn-link py-2 my-0" v-if="originalConfig.Configuration"
											:disabled="saving || !modified" @click="reset">RESET
							</button>
							<button class="btn py-2 my-0" :class="{'btn-success': modified, 'btn-light': !modified}"
											:disabled="saving || !modified" @click="save" v-if="modified">
								{{ modified ? "SAVE" : "EVERYTHING SAVED" }} <i class="ml-2 fa fa-save"/>
							</button>
						</div>

						<div class="d-inline-block p-2" v-else>
							<div class="bg-success text-white p-2 my-0">
								{{ message }} <i class="ml-2 fa fa-lock"/>
							</div>
						</div>
					</div>
				</project-navbar>

				<div class="row">
					<div class="col">
						<slot-config-editor :key="`${project.Code}-${config.Version}`"
																:identifier="`${project.Code}-${config.Version}`"
																v-model="config.Configuration" :disabled="editingDisabled"></slot-config-editor>
						<div v-if="configurationFreezed" class="p-3 bg-danger text-white text-center">
							THIS CONFIGURATION IS IN RELEASED STAGE, CANNOT EDIT
						</div>
					</div>
				</div>

				<block-note :disabled="editingDisabled" class="mt-2" v-model="notes" :synced="notesSaved"></block-note>
			</div>

		</template>
	</div>
</template>

<script>
import api from '@/api'
import ProjectNavbar from '@/components/projects/project-navbar'
import BlockNote from '@/components/slot-config-editor/block-note'
import {debounce} from "debounce";
import PreventUnload from 'vue-prevent-unload';
import SlotConfigEditor from "@/components/slot-config-editor/index";

export default {
	data() {
		return {
			project: null,
			config: null,
			originalConfig: null,
			loading: true,
			saving: false,
			message: null,
			debouncedSave: null,
			notesSaved: true
		}
	},
	mounted() {
		this.reset()
		this.debouncedSave = debounce(() => {
			api.Projects.saveProjectConfigNote(this.$route.params.uuid, this.$route.params.cuuid, this.notes)
			this.notesSaved = true
		}, 5000)
	},
	methods: {
		async reset() {
			this.project = await api.Projects.getProject(this.$route.params.uuid)
			this.config = await api.Projects.getProjectConfig(this.$route.params.cuuid)
			this.originalConfig = JSON.parse(JSON.stringify(this.config))
			await this.$store.commit('SlotConfigurator/currentSlot', this.config.Configuration)
			this.loading = false
			this.message = "Synced"
		},
		async save() {
			this.saving = true
			this.config = await api.Projects.saveProjectConfig(this.$route.params.cuuid, this.config.Configuration).catch(() => this.saving = false)
			this.originalConfig = JSON.parse(JSON.stringify(this.config))
			await this.$store.commit('SlotConfigurator/currentSlot', this.config.Configuration)
			this.saving = false
			this.message = "All progress saved"
		}
	},
	watch: {
		config: {
			deep: true,
			handler() {
				this.$store.commit('SlotConfigurator/currentSlot', this.config.Configuration)
			}
		}
	},
	computed: {
		modified() {
			return JSON.stringify(this.config.Configuration) !== JSON.stringify(this.originalConfig.Configuration)
		},
		editingDisabled() {
			return (this.configurationFreezed || !this.canWrite('projects_config'))
		},
		configurationFreezed() {
			return this.config.Status === 'released'
		},
		notes: {
			get() {
				return this.config.Notes
			},
			set(notes) {
				this.notesSaved = false
				this.debouncedSave()
				this.config.Notes = notes
			}
		}
	},
	components: {
		SlotConfigEditor,
		BlockNote, PreventUnload, ProjectNavbar
	}

}
</script>
