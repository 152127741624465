<script>
export default {
	name: "two-way-model",
	props: {
		value: {}
	},
	computed: {
		model: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			}
		}
	}
}
</script>
