<template>
	<div class="d-flex" style="gap: 10px">
		<div class="d-flex flex-column" style="gap: 10px">
			<div>
				<h5>ID</h5>
				<input type="text" v-model="model.id" class="form-control" :disabled="disabled">
			</div>

			<div>
				<h5>Bet multiplier</h5>
				<multiplier :min="1" v-model="model.priceMultiplier" :disabled="disabled"></multiplier>
			</div>
		</div>
		<div class="flex-grow-1">
			<h5>Prizes</h5>
			<section class="border p-2">
				<div class="text-muted" v-if="!model.prizes.length">No prizes defined</div>
				<div v-for="(prize, index) in model.prizes" :key="prize.id" v-else>
					<div class="d-flex">
						<prize-editor v-model="model.prizes[index]"
													:config="config" class="flex-grow-1" :key="prize.id" :disabled="disabled"></prize-editor>
						<button class="ml-2 btn btn-outline-danger py-0"
										@click="removePrize(index)" :disabled="disabled">
							<i class="fa fa-trash"></i>
						</button>
					</div>
					<hr>
				</div>
			</section>
			<div class="mt-3"></div>
			<button class="btn btn-outline-primary btn-sm" @click="addPrize">Add prize</button>
		</div>
	</div>
</template>

<script>
import Multiplier from "../prize/multiplier";
import PrizeEditor from "./prize-editor";
import TwoWayModel from "../../../mixins/two-way-model";
import String from '@/service/strings'
import Configurable from "../../../mixins/configurable";
import CanBeDisabled from "../../../mixins/can-be-disabled";

export default {
	name: "tier",
	components: {PrizeEditor, Multiplier},
	mixins: [TwoWayModel, Configurable, CanBeDisabled],
	mounted() {
		if (this.model.prizes === undefined) {
			this.$nextTick(() => this.model.prizes = [])
		}
	},
	methods: {
		addPrize() {
			this.model.prizes.push({
				id: String.getRandom(4),
				weight: 1,
				RNGs: []
			})
		},
		removePrize(index) {
			this.model.prizes.splice(index, 1)
		}
	}
}
</script>

<style scoped>

</style>
