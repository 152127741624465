<template>
	<grid :config="config" class="pb-2" :disabled="disabled" :classes="classes" :labels="labels"
		  @select="onCellClicked"></grid>
</template>

<script>
	import Grid from '../paylines/grid'

	export default {
		props: {
			disabled: {
				type: Boolean,
				default: false,
			},
			value: {
				type: Array
			},
			symbols: {
				type: Array,
				default: []
			},
			config: {
				type: Object
			}
		},
		data() {
			return {
				current: 'first',
				first: [1, 1],
				second: [0, 0],
			}
		},
		mounted() {
			this.checkReels()
		},
		watch: {
			value() {
				this.checkReels()
			}
		},
		methods: {
			nextPick() {
				if (this.current === 'first') {
					this.current = 'second'
				} else {
					this.current = 'first'
				}
			},
			onCellClicked(index) {
				console.log('onCellClicked', index, this.indexToPosition(index))
				if (this.current) {
					this[this.current] = this.indexToPosition(index)
				}
				this.$emit('input', this.calculatedOffset)
				this.nextPick()
			},
			getIndex(x, y) {
				return x + y * this.reels
			},
			indexToPosition(index) {
				let reel = index % this.reels;
				let line = Math.floor(index / this.reels);
				return [reel, line]
			},
			addOffset(position, offset) {
				let x = position[0] + offset[0]
				let y = position[1] + offset[1]
				return [x, y]
			},
			checkReels() {
				let index = 0
				do {
					console.log('First', this.first, 'Second', this.second, 'Reels', this.reels, 'Rows', this.rows)
					this.first = this.indexToPosition(index)
					this.second = this.addOffset(this.first, this.value)
					index++
				} while (this.second[0] < 0 || this.second[1] < 0 || this.second[0] >= this.reels || this.second[1] >= this.rows)
			}
		},
		computed: {
			labels() {
				let tmp = {}
				tmp[this.getIndex(this.first[0], this.first[1])] = this.symbols[0];
				tmp[this.getIndex(this.second[0], this.second[1])] = this.symbols[1];
				return tmp
			},
			classes() {
				let tmp = {}
				tmp[this.getIndex(this.first[0], this.first[1])] = `symbol symbol-${this.symbols[0]}`;
				tmp[this.getIndex(this.second[0], this.second[1])] = `symbol symbol-${this.symbols[1]}`;
				return tmp
			},
			reels() {
				return this.config.reels.length
			},
			calculatedOffset() {
				let x = this.second[0] - this.first[0]
				let y = this.second[1] - this.first[1]
				return [x, y]
			}
		},
		components: {
			Grid
		}
	}
</script>
