import { render, staticRenderFns } from "./game-type.vue?vue&type=template&id=60ab3831&scoped=true&"
import script from "./game-type.vue?vue&type=script&lang=js&"
export * from "./game-type.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60ab3831",
  null
  
)

export default component.exports