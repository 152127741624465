<template>
	<div>
		<h6>Symbol's occurrences</h6>
		<multiplier :disabled="disabled" v-model="model.rules.occurrences" :min="1" :max="100" :step="1"></multiplier>
	</div>
</template>

<script>
import Multiplier from "../../../prize/multiplier";

export default {
	name: "number-of-symbols",
	components: {Multiplier},
	props: {
		value: {
			type: Object
		},
		config: {
			type: Object
		},
		disabled: {
			default: false,
			type: Boolean
		}
	},
	computed: {
		model: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit("input", value)
			}
		}
	}
}
</script>

<style scoped>

</style>
