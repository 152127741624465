<script>
import FreespinMultiplier from "./freespin-multiplier";

export default {
	extends: FreespinMultiplier
};
</script>

<style scoped>

</style>
