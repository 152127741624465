<template>
	<div>
		<div class="text-muted text-small d-flex align-items-center">
			<div class="mr-4">
				<i class="fa fa-money-bill-wave fa-5x text-success"></i>
			</div>
			<div>Modifier will ends when player wins something.</div>
		</div>
		<div class="mt-3">
			<h6>Number of winnings</h6>
			<multiplier :disabled="disabled" v-model="model.rules.wins" :min="1" :step="1"></multiplier>
		</div>
	</div>
</template>

<script>
	import Multiplier from "../../../prize/multiplier";
	export default {
		name: "until-winning",
		components: {Multiplier},
		props: {
			value: {
				type: Object
			},
			config: {
				type: Object
			},
			disabled: {
				default: false,
				type: Boolean
			}
		},
		computed: {
			model: {
				get() {
					return this.value
				},
				set(value) {
					this.$emit("input", value)
				}
			}
		}
	}
</script>

<style scoped>

</style>
