<template>
	<div>

		<div class="mt-5">
			<h4 class="text-center">Paytable summary</h4>
			<div class="row justify-content-center">
				<div class="col-lg-6">
					<table class="table table-borderless table-striped table-shrikned">
						<thead>
						<tr>
							<th>Symbol</th>
							<th class="text-center" v-for="(i, index) in reels" v-if="index >= 0">{{ index + 1 }}</th>
						</tr>
						</thead>
						<tbody>
						<tr v-for="symbol in symbols">
							<td>
								<symbol-icon :symbol="symbol" :compact="true"></symbol-icon>
							</td>
							<td v-for="(prize, multiplier) in multipliers[symbol.id]" v-if="multiplier >= 1">
								<prize-viewer :prize="prize"></prize-viewer>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
			<hr>
		</div>

		<div class="mt-5">
			<h4>Paytable editor</h4>
			<table class="table">
				<template v-for="symbol in symbols">
					<thead>
					<tr>
						<th>SYMBOL</th>
						<th class="text-center" v-for="(reel, index) in reels" v-text="`x${index+1}`"></th>
					</tr>
					</thead>
					<tr>
						<td>
							<symbol-icon :config="config" :symbol="symbol"></symbol-icon>
						</td>
						<td v-for="(prize, multiplier) in multipliers[symbol.id]">
							<prize :config="config" :disabled="disabled" class="bg-light border"
										 v-model="multipliers[symbol.id][multiplier]"></prize>
						</td>
					</tr>
				</template>
			</table>
			<button class="btn btn-danger" @click="reset">Reset</button>

		</div>
	</div>
</template>

<script>
import SymbolIcon from "./symbols/symbol-icon";
import Prize from "./prize/";
import ObjTools from '@/service/objects'
import PrizeViewer from "./prize/prize-viewer";
import TwoWayModel from "../../mixins/two-way-model";
import Configurable from "../../mixins/configurable";
import CanBeDisabled from "../../mixins/can-be-disabled";

export default {
	components: {PrizeViewer, SymbolIcon, Prize},
	mixins: [TwoWayModel, Configurable, CanBeDisabled],
	data() {
		return {
			ready: false
		}
	},
	methods: {
		fixSymbols() {
			this.symbols.forEach((s) => {

				const symbol_id = `${s.id}`
				if (!this.multipliers[symbol_id]) {
					this.$set(this.multipliers, symbol_id, {})
				}
			})
		},
		fixMultipliers() {

			this.symbols.forEach(symbol => {
				console.log(`Symbol ${symbol.id} `)
				this.reels.forEach((reel, index) => {
					let multiplier = `${parseInt(index) + 1}`

					console.log(`Symbol ${symbol.id} Multiplier ${multiplier}`)

					if (this.multipliers[symbol.id][multiplier] === undefined) {
						this.$set(this.multipliers[symbol.id], multiplier, null)
					}
				})
			})
		},
		fixValue() {
			if (this.multipliers === undefined || Array.isArray(this.multipliers) || this.multipliers === null) {
				this.multipliers = {}
			}

			this.fixSymbols()
			this.fixMultipliers()

			ObjTools.filterByKeys(this.multipliers, this.symbols.map(s => s.id))
		},
		reset() {
			this.multipliers = {}
			this.fixValue()
		}
	},
	mounted() {
		//this.fixValue()
	},
	computed: {
		symbols() {
			return this.config.symbols
		},
		extraCurrencies() {
			return this.config.editorConfig.extraCurrencies
		},
		reels() {
			return this.config.reels
		},
		multipliers: {
			get() {
				return this.model.paytable.multipliers
			}, set(multipliers) {
				this.model.paytable.multipliers = multipliers
			}
		}
	},
	watch: {
		multipliers() {
			this.fixValue()
		}
	}
}
</script>
