var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar navbar-expand-lg navbar-light bg-light"},[_vm._m(0),_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarNav"}},[_c('ul',{staticClass:"navbar-nav"},[(this.canRead('projects_config'))?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
					name: 'projects-editor-uuid-cuuid-settings',
					params: {
						uuid: _vm.project.Uuid,
						cuuid: _vm.projectConfig.Uuid
					}
				}}},[_vm._v("Settings")])],1):_vm._e(),(this.canRead('simulator'))?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
					name: 'projects-editor-uuid-cuuid-tests-index',
					params: {
						uuid: _vm.project.Uuid,
						cuuid: _vm.projectConfig.Uuid
					}
				}}},[_vm._v("Tests")])],1):_vm._e(),(this.canRead('spinners'))?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{
					name: 'projects-editor-uuid-cuuid-config-index',
					params: {
						uuid: _vm.project.Uuid,
						cuuid: _vm.projectConfig.Uuid
					}
				}}},[_vm._v("Configuration")])],1):_vm._e()])]),_vm._t("default")],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarNav","aria-controls":"navbarNav","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})])
}]

export { render, staticRenderFns }