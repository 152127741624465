<template>
	<div>
		<h6>Row number</h6>
		<row-selector :config="config" v-model="model.rules.rows"></row-selector>
	</div>
</template>

<script>
	import RowSelector from "../../../../ui/RowSelector";
	export default {
		name: "row",
		components: {RowSelector},
		props: {
			value: {
				type: Object
			},
			config: {
				type: Object
			},
			disabled: {
				default: false,
				type: Boolean
			}
		},
		computed: {
			model: {
				get() {
					return this.value
				},
				set(value) {
					this.$emit("input", value)
				}
			},
			lines() {
				let ret = [];

				for (let i = 0; i < this.config.visibleLines; i++) {
					ret.push(i);
				}

				return ret
			}
		}
	}
</script>

<style scoped>

</style>
