<template>
	<div>
		<table class="table table-borderless">
			<thead>
			<tr>
				<th>Symbol</th>
				<th>Name</th>
				<th v-if="!disabled">Actions</th>
			</tr>
			</thead>
			<draggable tag="tbody" v-model="_symbols" group="symbols" handle=".cursor-grabbable" :disabled="disabled">
				<tr v-for="(symbol, index) in _symbols">
					<td class="py-1 p-0">
						<symbol-icon :config="config" :symbol="_symbols[index]" class="cursor-grabbable"></symbol-icon>
					</td>
					<td class="w-100">
						<input ref="symbols" class="form-control" :value="_symbols[index].name"
									 @input="e => updateSymbol(index, e)"
									 placeholder="Symbol name" :tabindex="index+1" @keyup.enter="addSymbol(index+1)"
									 @keyup.delete="_symbols[index].name === '' ? removeSymbol(index) : null"
									 :disabled="disabled">
						<symbol-modifiers :disabled="disabled" :config="config"
															v-model="_symbols[index].modifiers"></symbol-modifiers>
					</td>
					<td class="d-flex" v-if="!disabled">
						<button tabIndex="-1" class="btn" @click="addSymbol(index+1)">
							<i class="fa fa-plus text-light"></i>
						</button>
						<button tabIndex="-1" class="btn" @click="removeSymbol(index)" :disabled="_symbols.length <= 1">
							<i class="fa fa-trash text-light"></i>
						</button>
					</td>
				</tr>
			</draggable>
		</table>
		<div class="text-right" v-if="!disabled">
			<button class="btn btn-success px-5" @click="addSymbol(_symbols.length)"><i
					class="fa fa-plus"></i></button>
		</div>
	</div>
</template>

<script>
import SymbolIcon from "./symbols/symbol-icon";
import SymbolModifiers from "./modifiers/symbol-modifiers";
import TwoWayModel from "../../mixins/two-way-model";

export default {
	components: {
		SymbolModifiers,
		SymbolIcon
	},
	mixins: [TwoWayModel],
	props: {
		config: {
			type: Object
		},
		disabled: {
			default: false,
			type: Boolean
		}
	},
	mounted() {
		if (this._symbols.length === 0) {
			this.addSymbol(0)
		}
	},
	methods: {
		getNextIndex() {
			let current = 1
			let found = false

			do {
				if (this._symbols.some(s => s.id === current)) {
					current++
				} else {
					found = true
				}
			} while (!found)

			return current
		},
		addSymbol(index) {
			this._symbols.splice(index, 0, {
				id: `${this.getNextIndex()}`,
				name: '',
				modifiers: []
			})
			this.$nextTick(_ => {
				this.$refs.symbols[index].focus()
			})
			this.refreshIndexes()
		},
		removeSymbol(index) {
			this._symbols.splice(index, 1)
			this.$nextTick(_ => {
				this.$refs.symbols[index - 1].focus()
			})
			this.refreshIndexes()
		},
		refreshIndexes() {
			this._symbols.map((s, index) => s.id = `${index + 1}`)
		},
		updateSymbol(index, event) {
			this._symbols[index].name = event.target.value
		}
	},
	computed: {
		_symbols: {
			get() {
				return this.model.symbols
			},
			set(value) {
				let symbols = value.map((s, i) => ({
					id: `${i + 1}`,
					name: s.name,
					modifiers: s.modifiers !== undefined ? s.modifiers : []
				}));
				this.model.symbols = symbols
			}
		}
	},
}
</script>
