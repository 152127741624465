export default [
	{
		type: 'sticky',
		name: 'Sticky',
		description: 'A Sticky Symbol will stay in place to increase your chances of cashing on subsequent spins.',
		icon: 'icons/symbol-modifiers/sticky.png',
		default: () => ({})
	},
	{
		type: 'shifting',
		name: 'Shifting',
		description: 'Shifting symbols can start at the fifth reel and work their way left towards Reel 1, or start at Reel 1 and go right.',
		icon: 'icons/symbol-modifiers/shifting.png',
		default: () => ({direction: 'ltr', speed: 1})
	},
	{
		type: 'expanding',
		name: 'Expanding',
		description: 'Expanding symbols work by expanding over the length of a reel from top to bottom.',
		icon: 'icons/symbol-modifiers/expanding.png',
		default: () => ({direction: 'vertical'})
	},
]
