<template>
	<div>
		<grid :config="config" :classes="classes" :disabled="disabled" @select="updateModel"></grid>
	</div>
</template>
<script>

import Grid from "../slot-config-editor/paylines/grid";

export default {
	name: 'row-selector',
	components: {Grid},
	props: {
		config: {},
		value: {
			type: Array,
			default: () => []
		},
		disabled: {}
	},
	methods: {
		getIndex(column, row) {
			return column + row * this.reels.length
		},
		updateModel(newVal) {
			const reel_id = Math.floor(newVal / this.config.reels.length)
			console.log('xxxxx', newVal, reel_id)
			let tmp = this.model.slice()

			if (tmp.includes(reel_id)) {
				tmp = this.model.slice().filter(i => i !== reel_id)
			} else {
				tmp.push(reel_id)
				tmp.sort()
			}
			this.model = tmp
		}
	},
	computed: {
		reels() {
			return this.config.reels
		},
		model: {
			get() {
				return this.value
			},
			set(_val) {
				this.$emit('input', _val)
			},
		},
		classes() {
			let tmp = {}

			for (let row_id = 0; row_id < this.config.visibleLines; row_id++) {
				if (this.model.includes(row_id)) {
					for (let i = 0; i < this.config.reels.length; i++) {
						tmp[this.getIndex(i, row_id)] = "bg-success"
					}
				}
			}

			return tmp
		},
	}
}
</script>
