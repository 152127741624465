<template>
	<div>
		<div class="d-flex flex-wrap">
			<draggable v-model="model.paytable.paylines" group="paylines" handle=".cursor-grabbable" :disabled="disabled">
				<div class="card d-inline-flex m-1" v-for="(payline, index) in model.paytable.paylines" :key="index">
					<div class="card-header text-small p-1 cursor-grabbable">
						Payline {{ (index+1) | numeral('Oo') }}

					</div>
					<div class="card-body p-1 d-flex justify-content-center align-items-center">
						<payline-viewer :config="config" :enabled="!disabled" :selected-class="`payline-${index+1}`" v-model="model.paytable.paylines[index]"></payline-viewer>
					</div>
					<div class="card-footer pt-0 pb-1 text-center" v-if="!disabled">
						<button class="btn btn-outline-dark border-0 py-1 btn-sm text-small"
										@click="reset(index)">RESET
						</button>
						<button class="btn btn-outline-danger border-0 py-1 btn-sm text-small"
										@click="deletePayline(index)">DELETE
						</button>
					</div>
				</div>
			</draggable>
		</div>

		<button class="mt-5 btn btn-primary" @click="addPayline" v-if="!disabled">ADD PAYLINE</button>

		<paylines-direction-select :disabled="disabled" class="mt-3" v-model="model.paylines_direction"></paylines-direction-select>
	</div>
</template>

<script>
import PaylineViewer from './paylines/payline-viewer'
import PaylinesDirectionSelect from "./paylines/paylines-direction-select";

export default {
	props: {
		value: {
			type: Object
		},
		config: {
			type: Object
		},
		disabled: {
			default: false,
			type: Boolean
		}
	},
	computed: {
		model: {
			get() {
				return this.value
			},
			set(_val) {
				this.$emit("input", _val)
			}
		},
	},
	methods: {
		addPayline() {
			this.model.paytable.paylines.push([])
		},
		deletePayline(index) {
			this.model.paytable.paylines.splice(index, 1)
		},
		reset(index) {
			this.model.paytable.paylines.splice(index, 1, [])
		}
	},
	components: {
		PaylinesDirectionSelect,
		PaylineViewer
	}
}
</script>
