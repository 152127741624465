<template>
	<table class="table table-borderless">
		<thead class="border-bottom">
		<tr>
			<th>Symbol</th>
			<th class="text-center" v-for="(stats, reel_id) in symbolCounters">
				{{ (reel_id+1) | numeral('Oo') }} Reel
			</th>
		</tr>
		</thead>
		<tr v-for="symbol in symbols">
			<td>
				<symbol-icon :config="config" :symbol="symbol"></symbol-icon>
			</td>
			<td class="text-right align-middle" v-for="(stats, reel_id) in symbolCounters">
				<template v-if="symbolStats.id === symbol.id" v-for="symbolStats in stats">
					<span class="text-small text-light mr-2"
						  v-text="`${symbolStats.counter}/${reels[reel_id].length}`"></span>
					<span class="text-small font-weight-bold">
						{{(symbolStats.counter / reels[reel_id].length) | numeral('0,0.0%')}}
					</span>
					<div class="progress">
						<div class="progress-bar" role="progressbar"
							 :style="{width: `${(symbolStats.counter / (reels[reel_id].length) * 100).toFixed(0)}%`}"
							 aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
					</div>
					<div>
						{{ calculateSummary(symbol.id, reel_id+1)| numeral('0.00000%') }}
					</div>
				</template>
			</td>
		</tr>
		<tfoot class="border-top">
		<tr>
			<td class="text-light">Total</td>
			<td class="font-weight-bold text-center" v-for="reel in reels" v-text="reel.length"></td>
			<td></td>
		</tr>
		</tfoot>
	</table>
</template>

<script>
	import SymbolIcon from "../symbols/symbol-icon";

	export default {
		components: {SymbolIcon},
		props: {
			config: {
				type: Object
			}
		},
		methods: {
			symbolsMap(reel) {
				let tmp = {}

				this.symbols.forEach(s => {
					tmp[s.id] = {...s, counter: 0}
				})

				reel.forEach(s => {
					tmp[s].counter++
				})

				tmp = Object.values(tmp).sort(function (a, b) {
					if (a.counter === b.counter) {
						return a.id < b.id
					}
					return a.counter < b.counter ? 1 : -1;
				})

				return tmp
			},
			calculateSummary(symbol_id, counter) {

				return this.symbolCounters.map((sc, reel_id) => {
					return sc.filter(s => s.id === symbol_id)
							.map(s => s.counter / this.reels[reel_id].length)
				})
						.filter((_, index) => index < counter)
						.reduce((c, p) => c * p, 1)

			}
		},
		computed: {
			symbols() {
				return this.config.symbols
			},
			reels() {
				return this.config.reels
			},
			symbolCounters() {
				return this.reels.map(r => this.symbolsMap(r))
			}
		}
	}
</script>
