<template>
	<div class="my-3">
		<draggable v-model="features" handle=".handle" group="features">
			<div v-for="(feature, index) in features">
				<div class="text-center text-small text-black-50 handle cursor-grabbable"> - Move -</div>
				<div class="mr-2 d-inline-flex align-items-center">
					<span class="font-weight-bold text-uppercase" v-text="feature.name"></span>
					<button class="ml-3 btn btn-sm text-small btn-outline-danger" @click="removeFeature(index)"
									v-if="!disabled">REMOVE
						FEATURE
					</button>
				</div>

				<div class="d-flex align-items-center py-3">
					<div class="text-small mr-2">
						<span class="mr-1">Type</span>
						<span class="font-weight-bold text-uppercase" v-text="feature.type"></span>
					</div>
				</div>
				<div class="border p-3">
					<div class="w-25">
						<h5>Name</h5>
						<input :disabled="disabled" type="text" class="form-control"
									 v-model="feature.name">
					</div>
					<hr>
					<div class="my-3" :disabled="disabled" :config="config" :is="feature.type"
							 v-model="feature.options"></div>
				</div>
				<hr>
			</div>
		</draggable>

		<template v-if="!disabled">
			<h4>Add new feature</h4>
			<div class="d-flex flex-wrap">
				<div class="card m-2 bonus-card" v-for="feature in availableFeatures">
					<div class="card-header" v-text="feature.name"></div>
					<div class="card-body p-2 py-4 text-dark text-small" v-text="feature.description"></div>
					<div class="card-footer p-0 border-0">
						<button class="btn btn-primary border-0 w-100" @click="addFeature(feature)">ADD FEATURE</button>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import Scatter from './features/scatter'
import Herbie from './features/herbie'
import CustomPattern from './features/custom-pattern'
import PaylinePattern from './features/payline-pattern'
import FreespinMultiplier from "./features/freespin-multiplier";
import TumblingMultiplier from "./features/tumbling-multiplier";
import TwoWayModel from "../../mixins/two-way-model";

export default {
	components: {
		FreespinMultiplier,
		Scatter,
		Herbie,
		CustomPattern,
		PaylinePattern,
		TumblingMultiplier
	},
	mixins: [
		TwoWayModel,
	],
	props: {
		config: {
			type: Object
		},
		disabled: {
			default: false,
			type: Boolean
		}
	},
	methods: {
		addFeature(feature) {
			let feature_obj = {
				name: feature.name,
				type: feature.type,
				options: feature.default()
			}
			this.features.push(feature_obj)
		},
		removeFeature(index) {
			this.features.splice(index, 1)
		}
	},
	computed: {
		features: {
			get() {
				return this.model.features
			},
			set(features) {
				this.model.features = features
			}
		},
		extraCurrencies() {
			return this.config.editorConfig.extraCurrencies
		},
		availableFeatures() {
			let features = [
				{
					name: "Scatter",
					type: "scatter",
					description: "Multiply symbol in each position of the grid",
					default() {
						return []
					}
				},
				{
					name: "Herbie",
					type: "herbie",
					description: "Gives the prize of multiplier located by winning offset if this pattern is on some payline",
					default() {
						return {
							first: "1",
							second: "1",
							offset: [0, 0],
							winning_offset: [2, 0],
							winning_symbol_multiplier: 0
						}
					}
				},
				{
					name: "Custom Pattern",
					type: "custom-pattern",
					description: "Gives prize if this pattern is on some payline",
					default() {
						return {
							first: "1",
							second: "2",
							offset: [2, 0],
							prize: {
								multiplier: 0,
								freespins: 0,
								extra: vm.extraCurrencies.map(ec => {
											return {
												type: ec,
												value: 0
											}
										}
								)
							}
						}
					}
				},
				{
					name: "Payline Pattern",
					type: "payline-pattern",
					description: "Gives prize if 2d pattern is on some payline",
					default() {
						return {
							first: "1",
							second: "2",
							offset: 1,
							prize: {
								multiplier: 0,
								freespins: 0,
								extra: vm.extraCurrencies.map(ec => {
											return {
												type: ec,
												value: 0
											}
										}
								)
							}
						}
					}
				},
				{
					name: "Freespin multiplier",
					type: "freespin-multiplier",
					description: "A series of multiplier to be applied during free spins",
					default: () => ({type: undefined})
				},
			]

			if (this.config.falling_blocks_enabled) {
				features.push({
					name: "Tumbling multiplier",
					type: "tumbling-multiplier",
					description: "A list of capped multipliers valid during tumblings",
					default: () => ({
						type: "tumbling",
						prizes: [
							{
								multiplier: 1
							},
							{
								multiplier: 2
							}
						]
					})
				})
			}

			return features
		}
	}
}
</script>

<style scoped lang="scss">
.bonus-card {
	width: 200px;
}
</style>
