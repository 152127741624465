<template>
	<div>
		<div v-if="model.type === undefined">
			<div>
				<h5>Select free spin multiplier type</h5>

				<div class="d-flex flex-fill justify-content-between align-center">
					<button class="btn btn-primary flex-grow-1 mx-1" v-for="multiplier in availableMultipliers"
									@click="selectType(multiplier.type)">
						{{ multiplier.name }}<br/>
						<small>{{ multiplier.description }}</small>
					</button>
				</div>

			</div>
		</div>
		<div class="mt-2 bg-light border p-3" v-else>
			<h5 v-if="currentMultiplier !== undefined">{{ currentMultiplier.name }}'s options</h5>
			<div :is="model.type" v-model="model" :config="config" :disabled="disabled"></div>
		</div>
	</div>
</template>

<script>
import Fixed from "./freespin-multiplier/fixed";
import Rng from "./freespin-multiplier/rng";
import CappedList from "./freespin-multiplier/capped-list";
import Tumbling from "./freespin-multiplier/tumbling";

export default {
	name: "freespin-multiplier",
	components: {Tumbling, CappedList, Rng, Fixed},
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		value: {
			type: Object
		},
		config: {
			type: Object
		}
	},
	methods: {
		selectType(type) {
			this.model = {
				type,
				...this.findMultiplier(type).default()
			}
		},
		findMultiplier(type) {
			return this.availableMultipliers.filter(m => m.type === type).slice()[0]
		}
	},
	computed: {
		model: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit("input", val)
			}
		},
		currentMultiplier() {
			return this.findMultiplier(this.model.type)
		},
		availableMultipliers: () => [
			{
				"name": "Fixed",
				"description": "A fixed multiplier for every free spins",
				"type": "fixed",
				"default": () => ({
					"prize": {
						"multiplier": 2
					}
				})
			},
			{
				"name": "RNG",
				"description": "A weighted list of multiplier extracted before every free spins",
				"type": "rng",
				"default": () => ({
					"payouts": []
				})
			},
			{
				"name": "Capped list",
				"description": "A list of multiplier, adopted during free spins",
				"type": "capped-list",
				"default": () => ({
					"prizes": [{
						"multiplier": 2
					}]
				})
			},
			{
				"name": "Tumbling multiplier",
				"description": "A list of multiplier, adopted during tumbling in free spins",
				"type": "tumbling",
				"default": () => ({
					prizes: [
						{
							multiplier: 2
						},
						{
							multiplier: 3
						}
					]
				})
			},
		]
	}
}
</script>

<style scoped>

</style>
