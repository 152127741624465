<template>
	<div>

		<div class="d-flex mb-2" v-for="(jackpot, index) in model">
			<div class="flex-grow-1">
				<label class="text-small">Name</label>
				<input type="text" v-model="model[index].name" :key="index" class="form-control" :disabled="disabled">
			</div>
			<div style="width: 200px">
				<label class="text-small">Bet percentage</label>
				<multiplier v-model="model[index].bet_percentage" :min="0" :max="100" class="w-100" :disabled="disabled">%</multiplier>
			</div>
			<div>
				<label class="text-small">&nbsp;</label>
				<div>
					<button class="btn btn-outline-danger btn-sm" @click="removeJackpot(index)" :disabled="disabled">Remove</button>
				</div>
			</div>
		</div>

		<div class="mt-3">
			<button class="btn btn-outline-primary btn-sm" @click="addJackpot" :disabled="disabled">Add jackpot</button>
		</div>

	</div>
</template>

<script>
import Multiplier from "../prize/multiplier";

export default {
	name: "jackpot-config-model",
	components: {Multiplier},
	props: {
		value: {
			default: null,
			type: Array
		},
		disabled: {
			default: true,
			type: Boolean
		}
	},
	methods: {
		addJackpot() {
			this.model.push({
				name: `jackpot-${this.model.length + 1}`,
				bet_percentage: 0.01
			})
		},
		removeJackpot(index) {
			this.model.splice(index, 1)
		}
	},
	computed: {
		model: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit("input", value)
			}
		}
	}
}
</script>

<style scoped>

</style>
