<template>
	<div>
		<h6>Valid reels</h6>
		<reel-selector v-model="model.rules.reels" :disabled="disabled" :config="config"></reel-selector>
	</div>
</template>

<script>
	import ReelSelector from "../../../../ui/ReelSelector";
	export default {
		name: "reel",
		components: {ReelSelector},
		props: {
			value: {
				type: Object
			},
			config: {
				type: Object
			},
			disabled: {
				default: false,
				type: Boolean
			}
		},
		computed: {
			model: {
				get() {
					return this.value
				},
				set(value) {
					this.$emit("input", value)
				}
			}
		}
	}
</script>

<style scoped>

</style>
