import { render, staticRenderFns } from "./expanding.vue?vue&type=template&id=c59405fc&scoped=true&"
import script from "./expanding.vue?vue&type=script&lang=js&"
export * from "./expanding.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c59405fc",
  null
  
)

export default component.exports