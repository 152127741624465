<template>
	<div>
		<grid :config="config" :classes="classes" :disabled="disabled" @select="updateModel"></grid>
	</div>
</template>
<script>

import Grid from "../slot-config-editor/paylines/grid";

export default {
	name: 'reel-selector',
	components: {Grid},
	props: {
		config: {},
		value: {
			type: Array,
			default: () => []
		},
		disabled: {}
	},
	methods: {
		getIndex(column, row) {
			return column + row * this.reels.length
		},
		updateModel(newVal) {
			const reel_id = newVal % this.reels.length
			console.log('newVal', reel_id)

			let tmp = this.model.slice()

			if (tmp.includes(reel_id)) {
				tmp = this.model.slice().filter(i => i !== reel_id)
			} else {
				tmp.push(reel_id)
				tmp.sort()
			}

			this.model = tmp
		}
	},
	computed: {
		reels() {
			return this.config.reels
		},
		model: {
			get() {
				return this.value
			},
			set(_val) {
				this.$emit('input', _val)
			},
		},
		classes() {
			let tmp = {}

			for (let reel_id = 0; reel_id < this.reels.length; reel_id++) {
				console.log('Searching for', reel_id, 'into', this.model)
				if (this.model.includes(reel_id)) {
					for (let i = 0; i < this.config.visibleLines; i++) {
						console.log('Adding success classes', reel_id, i)
						tmp[this.getIndex(reel_id, i)] = "bg-success"
					}
				}
			}

			return tmp
		},
	}
}
</script>
