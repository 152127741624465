<template>
	<div>

		<div class="row">
			<div class="col">
				<div class="p-3">
					<div>
						<input v-model="value.editorConfig.keepFreespinReelsSynced" type="checkbox" id="keep-synced"
									 :disabled="disabled">
						<label class="ml-2"
									 for="keep-synced">Keep
							freespin reels synced with standard</label>
					</div>
				</div>
			</div>
			<div class="col" v-if="!disabled">
				<div class="text-right" v-if="JSON.stringify(_value.reels) !== JSON.stringify(_value.freespinReels)">
					<button class="my-2 btn btn-success" @click="copyFromStandard"
									v-if="currentTab === 'freespinReels'">Copy
						reels from Standard Reel
					</button>
					<button class="my-2 alert alert-info" v-if="currentTab === 'reels'"
									@click="currentTab = 'freespinReels'">
						Freespin reels are different from standard reels
					</button>
					<button class="my-2 alert alert-info" v-if="currentTab === 'reels'"
									@click="currentTab = 'overlayReels'">
						Overlay reels
					</button>
				</div>
			</div>
		</div>

		<ul class="nav nav-pills">
			<li class="nav-item" v-for="tab in tabs">
				<div class="cursor-pointer nav-link" :class="{active: tab.id === currentTab}" v-text="tab.name"
						 @click="currentTab = tab.id"></div>
			</li>
		</ul>

		<template v-if="currentTab === 'overlayReels'">
			<overlay-reels :disabled="disabled" :config="_value" v-model.sync="_value.overlay_reels"></overlay-reels>
		</template>

		<template v-else>
			<reels :disabled="disabled" class="py-2" v-model.sync="_value[currentTab]" :config="_value"></reels>
		</template>

	</div>
</template>

<script>
import Reels from './reels/reels'
import OverlayReels from "./reels/overlay-reels";

export default {
	data() {
		return {
			currentTab: 'reels'
		}
	},
	props: {
		value: {
			type: Object
		},
		config: {
			type: Object
		},
		disabled: {
			default: false,
			type: Boolean
		}
	},
	computed: {
		_value: {
			get() {
				return this.value
			},
			set(reel) {
				let r = this.value
				r[this.currentTab] = reel
				this.$emit('input', r)
			}
		},
		tabs() {

			return [
				{
					name: "Standard reels",
					id: 'reels',
					enabled: true
				},
				{
					name: "Freespin reels",
					id: 'freespinReels',
					enabled: !this.value.editorConfig.keepFreespinReelsSynced
				},
				{
					name: "Overlay reels",
					id: 'overlayReels',
					enabled: true
				}
			]
		}
	},
	methods: {
		copyFromStandard() {
			this._value.freespinReels = this._value.reels.slice().map(r => r.slice())
		},
		syncReels() {
			if (this.value.editorConfig.keepFreespinReelsSynced) {
				this.copyFromStandard()
			}
		}
	},
	components: {
		OverlayReels,
		Reels
	},
	watch: {
		'_value.reels'() {
			this.syncReels()
		},
		'_value.editorConfig.keepFreespinReelsSynced'(synced) {
			if (synced) {
				this.copyFromStandard()
			}
		}
	}
}
</script>
