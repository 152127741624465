<template>
	<div>
		<div class="payouts align-items-center" v-for="(payout, index) in payouts">
			<div class="text-small" v-text="`Payout #${index+1}`"></div>
			<div class="d-flex">
				<payout :show-freespins="showFreespins" :show-extra="showExtra" :show-multiplier="showMultiplier" :show-strategy="showStrategy" :config="config" :disabled="disabled" class="my-2" v-model="payouts[index]" @keyup.native.enter="addPayout"></payout>
				<div class="p-3" v-if="!disabled">
					<button class="btn" @click="deletePayout(index)">
						<i class="fa fa-trash text-light"></i>
					</button>
				</div>
			</div>
			<hr>
		</div>

		<div class="d-flex justify-content-around" v-if="!disabled">
			<button class="btn btn-primary" @click="sortPayouts" v-if="payouts.length > 1">Sort payouts</button>
			<button class="btn btn-primary" @click="addPayout">Add payout</button>
		</div>
	</div>
</template>

<script>
	import Payout from './payout'

	export default {
		props: {
			value: {
				type: Array,
				default: []
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			config: {
				type: Object
			},
			showExtra: {
				type: Boolean,
				default: true
			},
			showMultiplier: {
				type: Boolean,
				default: true
			},
			showFreespins: {
				type: Boolean,
				default: true
			},
			showStrategy: {
				type: Boolean,
				default: true
			},
		},
		methods: {
			addPayout() {
				this.payouts.push(null)
			},
			deletePayout(index) {
				this.payouts.splice(index, 1)
			},
			sortPayouts() {
				this.payouts.sort((a, b) => a.max > b.max ? 1 : -1)
			}
		},
		computed: {
			payouts: {
				get() {
					return this.value
				},
				set(payouts) {
					this.$emit('input', payouts)
				}
			}
		},
		components: {
			Payout
		}
	}
</script>
