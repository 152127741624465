<template>
	<div class="container-fluid">
		<ul class="nav nav-tabs">
			<li class="nav-item" v-for="tab in tabs">
				<div class="cursor-pointer nav-link" :class="{active: tab.id === currentTab}"
						 @click="currentTab = tab.id">
					<i class="mr-2" :class="tab.icon"/>
					<span v-text="tab.name"></span>
				</div>
			</li>
		</ul>

		<div class="py-2" v-if="model" :key="currentTab">
			<div :is="currentTab" :config="model" :disabled="disabled" v-model="model"></div>
		</div>

		<div class="mt-5">&nbsp;</div>
		<div class="mt-5">&nbsp;</div>
		<div class="mt-5">&nbsp;</div>
		<div class="mt-5">&nbsp;</div>
		<div class="mt-5">&nbsp;</div>
		<div class="mt-5">&nbsp;</div>
		<div class="mt-5">&nbsp;</div>

	</div>
</template>

<script>
import MainSettings from './main'
import Reels from './reels'
import Symbols from './symbols'
import Paylines from './paylines'
import Paytable from './paytable'
import Wilds from './wilds'
import Features from './features'
import Bonus from './bonus'
import Gamble from './gamble'
import BuyFeatures from './buy-features'
import ImportExport from './import-export'
import TwoWayModel from "../../mixins/two-way-model";

export default {
	name: 'slot-config-editor',
	mixins: [TwoWayModel],
	props: {
		value: {
			type: Object
		},
		identifier: {
			type: String,
			default: ""
		},
		disabled: {
			default: false,
			type: Boolean
		}
	},
	data() {
		return {
			currentTab: null
		}
	},
	methods: {
		resetConfiguration() {
			this.model = this.defaultConfiguration
			this.checkMissingRequiredData()
		},
		checkMissingRequiredData() {
			if (Array.isArray(this.model.paytable.multipliers)) {
				this.$nextTick(() => this.$set(this.model.paytable, "multipliers", {}))
			}

			if (this.model.gamble === undefined) {
				this.$nextTick(() => {
					this.$set(this.model, "gamble", {
						enabled: false,
						enabled_stages: [],
						limit: 7,
						choices: []
					})
				})
			}

			if (this.model.paylines_direction === undefined) {
				this.$nextTick(() => {
					this.$set(this.model, "paylines_direction", 0)
				})
			}

			if (this.model.overlay_reels === undefined) {
				this.$nextTick(() => {
					this.$set(this.model, "overlay_reels", [])
				})
			}

			if (this.model.falling_blocks_enabled === undefined) {
				this.$nextTick(() => {
					this.$set(this.model, "falling_blocks_enabled", false)
				})
			}

			if (this.model.keep_states_splitted === undefined) {
				this.$nextTick(() => {
					this.$set(this.model, "keep_states_splitted", false)
				})
			}

			if (this.model.jackpot === undefined) {
				this.$nextTick(() => {
					this.$set(this.model, "jackpot", this.defaultConfiguration.jackpot)
				})
			}
		}
	},
	computed: {
		tabs() {
			return [
				{
					name: 'Main',
					id: 'main-settings',
					icon: 'fa fa-cogs'
				},
				{
					name: 'Symbols',
					id: 'symbols',
					icon: 'fa fa-boxes'
				},
				{
					name: `Reels (${this.value ? this.value.reels.length : 0})`,
					id: 'reels',
					icon: 'fa fa-film'
				},
				{
					name: `Paylines (${this.value ? this.value.paytable.paylines.length : 0})`,
					id: 'paylines',
					icon: 'fa fa-braille'
				},
				{
					name: `Wilds (${this.value ? this.value.paytable.wilds.length : 0})`,
					id: 'wilds',
					icon: 'fa fa-gifts'
				},
				{
					name: 'Paytable',
					id: 'paytable',
					icon: 'fa fa-columns'
				},
				{
					name: `Features (${this.value ? this.value.features.length : 0})`,
					id: 'features',
					icon: 'fa fa-dice'
				},
				{
					name: `Bonus (${this.value ? this.value.bonuses.length : 0})`,
					id: 'bonus',
					icon: 'fa fa-dice-d20'
				},
				{
					name: 'Gamble',//this.value.gamble.enabled ? 'Gamble ON' : 'Gamble OFF',
					id: 'gamble',
					icon: 'fa fa-angle-double-up'
				},
				{
					name: 'Buy feature',//this.value.gamble.enabled ? 'Gamble ON' : 'Gamble OFF',
					id: 'buy-features',
					icon: 'fa fa-money-bill'
				},
				/*
			{
				name: 'Jackpot',//this.value.gamble.enabled ? 'Gamble ON' : 'Gamble OFF',
				id: 'jackpot',
				icon: 'fa fa-angle-double-up'
			},

				 */
				{
					name: 'Import/Export',
					id: 'import-export',
					icon: 'fa fa-truck'
				}
			]
		},
		defaultConfiguration() {
			return {
				reels: [],
				version: '0.0.0.0',
				symbols: [],
				freespinReels: [],
				visibleLines: 3,
				paddingLines: 1,
				coins: [],
				paytable: {
					paylines: [],
					multipliers: {},
					wilds: []
				},
				features: [],
				bonuses: [],
				gamble: {
					enabled: false,
					enabled_stages: [],
					limit: 7,
					choices: []
				},
				jackpot: {
					enabled: false,
					jackpots: []
				},
				overlay_reels: [],
				paylines_direction: 0,
				editorConfig: {
					keepFreespinReelsSynced: false,
					extraCurrencies: [],
					targetRTP: 96
				},
				falling_blocks_enabled: false,
				keep_states_splitted: false
			}
		},

	},
	created() {
		console.log('NEW SLOT CONFIG CREATED')
		if (this.model === null) {
			this.resetConfiguration()
		}

		this.currentTab = this.tabs[0].id

		this.checkMissingRequiredData()
	},
	mounted() {
		console.log('NEW SLOT CONFIG MOUNTED')
		for (let field in this.defaultConfiguration) {
			let config = this.defaultConfiguration[field]
			if (this.value[field] === undefined) {
				this.$set(this.value, field, config)
			}
		}
	},
	components: {
		MainSettings,
		Reels,
		Symbols,
		Paylines,
		Paytable,
		Wilds,
		Features,
		Bonus,
		Gamble,
		BuyFeatures,
		ImportExport
	}
}
</script>
