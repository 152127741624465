<template>
  <div>
    <button class="btn btn-link text-small"
            :class="{'font-weight-bold': usedModifiers.length > 0}"
            @click="panelOpen = !panelOpen">
      <i class="fa" :class="{'fa-arrow-down': !panelOpen, 'fa-arrow-up': panelOpen}"></i> {{ modifiersList }}
    </button>

    <div class="border p-3" v-if="panelOpen">

      <div class="text-small text-muted" v-if="usedModifiers.length > 0">Modifiers order is important, execution follow
        this current order ({{ usedModifiers.join(", ") }})
      </div>
      <div class="text-small text-muted">Some modifiers are not compatible, as i.e. shifting + sticky</div>

      <draggable v-model="models" group="model"
                 handle=".handle" :disabled="disabled">
        <div class="border my-2 p-2 d-flex" v-for="(modifier, index) in models" :key="modifier.symbol">
          <symbol-modifier :disabled="disabled" :config="config" class="flex-grow-1" v-model="models[index]"></symbol-modifier>
          <button tabindex="-1" class="btn" @click="models.splice(index, 1)" v-if="!disabled"><i
              class="fa fa-trash text-light"></i></button>
        </div>
      </draggable>

      <template v-if="!disabled && unusedModifiers.length > 0">
        <hr>
        <h5 class="my-3">Add new symbol modifier</h5>
        <div class="d-flex">
          <button v-for="modifier in unusedModifiers" class="btn btn-sm bg-light border mr-3 p-2 text-center"
                  style="width: 240px;" @click="addModifier(modifier)">
            <div class="text-small font-weight-bold" v-text="modifier.name"></div>
            <img :src="modifier.icon" class="img-fluid mt-2" style="width: 32px; height: 32px;">
            <div class="text-small my-2 p-2" v-text="modifier.description"></div>
            <div class="mt-2">
              <i class="fa fa-plus text-primary"></i>
            </div>
          </button>
        </div>
      </template>
    </div>

  </div>
</template>

<script>
import SymbolModifier from "./symbol-modifier";
import modifiers from './modifiers'

export default {
  name: "symbol-modifiers",
  components: {SymbolModifier},
  props: {
    value: {
      type: Array,
      default: () => []
    },
    config: {
      type: Object
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },
  data: () => ({panelOpen: false}),
  methods: {
    addModifier(modifier) {
      this.models.push({
        type: modifier.type,
        configuration: {
          ...modifier.default(),
          startConditions: [],
          endConditions: []
        }
      })
    }
  },
  mounted() {
    if (this.models.length === 0) {
      this.$nextTick(() => this.$set(this, "models", []))
    } else {
      this.panelOpen = true
    }
  },
  computed: {
    models: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    },
    modifiersList() {
      return this.usedModifiers.length > 0 ? `Modifiers (${this.usedModifiers.join(" / ")})` : 'No modifiers'
    },
    usedModifiers() {
      return this.models.map(m => m.type)
    },
    unusedModifiers() {
      return this.allModifiers.slice()//.filter(m => !this.usedModifiers.includes(m.type))
    },
    allModifiers: () => modifiers
  }
}
</script>

<style scoped>

</style>
