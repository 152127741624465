<template>
	<div class="border p-2 m-1">
		<h5>Paylines Direction</h5>
		<div class="form-group" v-for="(option, index) in options" :key="option.value">
			<input :disabled="disabled" :name="`direction-${index}`" type="radio" :value="option.value" v-model="model" :id="`direction-${index}`"/>
			<label class="form-check-label ml-2" :for="`direction-${index}`"
				   v-text="option.name"></label>
		</div>
	</div>
</template>

<script>
	export default {
		name: "paylines-direction-select",
		props: {
			value: {
				type: Number
			},
      disabled: {
        default: false,
        type: Boolean
      }
    },
		computed: {
			options() {
				return [
					{
						name: 'Left to right',
						value: 0
					},
					{
						name: 'Right to left',
						value: 1
					},
					{
						name: 'Both',
						value: 2
					}
				]
			},
			model: {
				get() {
					return this.value
				},
				set(_val) {
					this.$emit("input", _val)
				}
			},
		}
	}
</script>

<style scoped>

</style>
