<template>
	<div>
		<div>
			<h5>Direction</h5>
			<div class="d-inline-flex align-items-center m-1" v-for="direction in directions">
				<input :disabled="disabled" type="radio" v-model="model.direction" :value="direction.value"> <i class="ml-1 fa" :class="`fa-arrow-${direction.icon}`"></i>
			</div>
		</div>
		<div class="mt-3">
			<h5>Speed</h5>
			<multiplier :disabled="disabled" :min="1" :step="1" :max="config.reels.length" v-model="model.speed"></multiplier>
		</div>
	</div>
</template>

<script>
	import Multiplier from "../../prize/multiplier";
	export default {
		name: "shifting",
		components: {Multiplier},
		props: {
			value: {
				type: Object
			},
			config: {
				type: Object
			},
			disabled: {
				default: false,
				type: Boolean
			}
		},
		computed: {
			model: {
				get() {
					return this.value
				},
				set(value) {
					this.$emit("input", value)
				}
			},
			directions: () => [
				{
					icon: 'right',
					value: 'ltr'
				},
				{
					icon: 'up',
					value: 'btt'
				},
				{
					icon: 'left',
					value: 'rtl'
				},
				{
					icon: 'down',
					value: 'ttb'
				},
			]
		}
	}
</script>

<style scoped>

</style>
