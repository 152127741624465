<template>
	<div v-if="prize">
		<div class="p-2">
			<div class="bg-light border p-2 mb-2" v-if="showMultiplier">
				<div class="d-flex align-content-center flex-column text-center">
					<div class="text-nowrap text-small font-weight-bold">
						Multiplier <i class="ml-2 fa fa-calculator text-light"/>
					</div>
					<div class="d-inline-flex justify-content-center">
						<multiplier :disabled="disabled" v-model="prize.multiplier" :min="0"></multiplier>
					</div>
				</div>

				<div class="d-flex align-content-center flex-column text-center" v-if="showStrategy && prize.multiplier > 0">
					<div class="text-nowrap text-small font-weight-bold">
						Multiplier strategy <i class="ml-2 fa fa-calculator text-light"/>
					</div>
					<div>
						<select :disabled="disabled" v-model="prize.strategy" class="form-control">
							<option :value="strategy.code" v-for="strategy in strategies" v-text="strategy.title"></option>
						</select>
						<div v-if="currentStrategy" class="text-small text-light p-2"
								 v-text="currentStrategy.description"></div>
					</div>
				</div>
			</div>

			<div class="d-flex align-content-center flex-column text-center" v-if="showFreespins">
				<div class="text-nowrap text-small font-weight-bold">
					Freespins <i class="ml-2 fa fa-smile text-light"/>
				</div>
				<div class="d-inline-flex justify-content-center">
					<multiplier :disabled="disabled" v-model="prize.freespins" :min="0"></multiplier>
				</div>
			</div>

			<template v-for="(extra, index) in prize.extra" v-if="showExtra">
				<div class="d-flex align-content-center flex-column text-center">
					<div class="text-nowrap text-small font-weight-bold text-capitalize">
						{{ extra.type }} <i class="ml-2 fa fa-box-open text-light"/>
					</div>
					<div class="d-inline-flex justify-content-center">
						<multiplier :disabled="disabled" v-model="prize.extra[index].value" :min="0"></multiplier>
					</div>
				</div>
			</template>

			<template v-if="showJackpot && config.jackpot.enabled">
				<div class="d-flex align-content-center flex-column text-center">
					<div class="text-nowrap text-small font-weight-bold text-capitalize">
						Jackpot <i class="ml-2 fa fa-check text-light"/>
					</div>
					<div class="d-inline-flex justify-content-center">
						<select :disabled="disabled" v-model="prize.jackpot" class="form-control">
							<option :value="null">No</option>
							<option :value="jackpot.name" v-for="jackpot in config.jackpot.jackpots" v-text="jackpot.name"></option>
						</select>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import multiplier from "./multiplier";

export default {
	name: "prize",
	props: {
		value: {
			type: Object,
		},
		disabled: {
			type: Boolean,
			default: false
		},
		config: {
			type: Object
		},
		showExtra: {
			type: Boolean,
			default: true
		},
		showJackpot: {
			type: Boolean,
			default: true
		},
		showMultiplier: {
			type: Boolean,
			default: true
		},
		showFreespins: {
			type: Boolean,
			default: true
		},
		showStrategy: {
			type: Boolean,
			default: true
		},
	},
	methods: {
		fixPrize() {

			if (null === this.prize) {
				this.$nextTick(() => {
					this.prize = {
						multiplier: 0,
						strategy: 'line-bet',
						freespins: 0,
						extra: [],
						jackpot: null
					}
				})
			}


			if (this.prize) {

				if (undefined === this.prize.jackpot) {
					this.$nextTick(() => this.prize.jackpot = null)
				}

				this.$nextTick(() => {
					this.extraCurrencies.map(ec => {
						if (this.prize.extra.filter(e => e.type === ec).length === 0) {
							this.prize.extra.push({
								type: ec,
								value: 0
							})
						}
					})

					this.prize.extra = this.prize.extra.filter(e => this.extraCurrencies.includes(e.type))
				})

				if (this.prize.strategy === undefined) {
					this.$nextTick(() => this.prize.strategy = 'line-bet')
				}
			}

		}
	},
	mounted() {
		this.fixPrize()
	},
	computed: {
		strategies() {
			return [
				{
					code: 'line-bet',
					title: 'Line bet',
					description: 'Multiplier works on last line bet'
				},
				{
					code: 'avg-line-bet',
					title: 'Line bet Average',
					description: 'Multiplier works on last 150 line bets average'
				},
				{
					code: 'avg-line-bet-collected',
					title: 'Line bet Average (collected only with extra currencies wins)',
					description: 'Multiplier works on last 150 line bets average collected only if at least 1 extra currency coin is collected'
				},
				{
					code: 'total-bet',
					title: 'Total bet',
					description: 'Multiplier works on last total bet'
				}
			]
		},
		currentStrategy() {
			let filtered = this.strategies.slice().filter(s => s.code === this.prize.strategy)

			if (filtered.length > 0) {
				return filtered[0]
			}
		},
		prize: {
			get() {
				return this.value
			},
			set(prize) {
				this.$emit('input', prize)
			}
		},
		extraCurrencies() {
			return this.config.editorConfig.extraCurrencies
		}
	},
	components: {
		multiplier
	}
}
</script>

<style lang="scss" scoped>
.bg-extra {
	background-color: #f9f9f9;
}
</style>
