<template>
	<div>
		<h4>Reel string</h4>
		<reel-importer :disabled="disabled" :config="config" v-model="reel"></reel-importer>

		<div class="row">
			<div class="col-4">
				<h4 class="mt-4">Reel:</h4>
				<table class="table-borderless mt-3">
					<draggable tag="tbody" v-model="reel" group="reel" :disabled="disabled">
						<tr v-for="(symbol, index) in reel" :key="index" class="cursor-grabbable">
							<td class="text-light text-sm-center" v-text="`${index+1}`"></td>
							<td>
								<symbol-switcher :config="config" :disabled="disabled" :compact="true" v-model="reel[index]"></symbol-switcher>
							</td>
							<td v-if="!disabled">
								<button tabIndex="-1" class="btn" @click="addSymbol(index+1)">
									<i class="fa fa-plus text-light"></i>
								</button>
								<button tabIndex="-1" class="btn" @click="removeSymbol(index)">
									<i class="fa fa-trash text-light"></i>
								</button>
							</td>
						</tr>
					</draggable>
				</table>
				<button class="btn btn-dark mt-3" @click="addSymbol(reel.length)" v-if="!disabled"><i class="fa fa-plus"></i> ADD SYMBOL
				</button>
			</div>
			<div class="col">
				<h4 class="mt-4">Summary:</h4>
				<reel-summary :config="config" :reel="reel"></reel-summary>
			</div>
		</div>
	</div>
</template>

<script>
	import ReelImporter from './reel-importer'
	import ReelSummary from './reel-summary'
	import SymbolSwitcher from "../symbols/symbol-switcher";

	export default {
		props: {
			value: {
				type: Array,
				default: () => []
			},
			config: {
				type: Object
			},
			disabled: {
				default: false,
				type: Boolean
			}
		},
		components: {
			SymbolSwitcher,
			ReelImporter,
			ReelSummary
		},
		methods: {
			addSymbol(index) {
				this.reel.splice(index, 0, this.symbols[0].id)
			},
			removeSymbol(index) {
				this.reel.splice(index, 1)
			}
		},
		computed: {
			symbols() {
				return this.config.symbols
			},
			symbolsMap() {
				let tmp = {}
				this.symbols.forEach(s => tmp[s.id] = s)
				return tmp
			},
			reel: {
				get() {
					return this.value
				},
				set(reels) {
					this.$emit("input", reels)
				}
			}
		}
	}
</script>
