<template>
	<table class="table table-borderless table-shrikned d-block">
		<thead>
		<tr>
			<td></td>
			<td class="px-3 py-2 font-weight-bold text-small">Limit</td>
			<td class="px-3 py-2 font-weight-bold text-small">Steps</td>
			<td class="px-3 py-2 font-weight-bold text-small">Prize</td>
			<td class="px-3 py-2 font-weight-bold text-small">Probability</td>
		</tr>
		</thead>
		<tbody>
		<tr class="text-small" v-for="(payout, index) in sortedPayouts">
			<td class="py-1 text-small" v-text="`#${payout.originalIndex+1}`"></td>
			<td class="px-3 text-small py-1" v-text="payout.max"></td>
			<td class="px-3 text-small py-1" v-text="payout.steps"></td>
			<td class="px-3 py-1">
				<prize-viewer :config="config" class="font-weight-bold" :prize="payout.prize"></prize-viewer>
			</td>
			<td class="px-3 py-1">
				<div class="d-flex align-items-center">
					<small class="mr-2" v-text="`${payout.probability.toFixed(1)}%`"></small>
					<div class="progress w-100">
						<div class="progress-bar" role="progressbar"
							 :style="{width: `${Math.round(payout.probability / maxProbability * 100)}%`}"
							 aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
					</div>
				</div>
			</td>
		</tr>
		</tbody>
	</table>
</template>

<script>
	import PrizeViewer from "../prize/prize-viewer";

	export default {
		components: {PrizeViewer},
		props: {
			payouts: {
				type: Array,
				default: () => []
			},
			config: {
				type: Object
			}
		},
		methods: {
			getSteps(index) {
				let prev_level_max = (this.payouts[index - 1] ? this.payouts[index - 1].max : 0)
				return this.payouts[index].max - prev_level_max
			},
			getProbability(index) {
				return (this.getSteps(index) / this.maxValue * 100)
			}
		},
		computed: {
			sortedPayouts() {
				return this.payoutWithProbability.slice().sort((a, b) => a.probability < b.probability ? 1 : -1)
			},
			payoutWithProbability() {
				return this.payouts.map((p, index) => {
					return {
						...p,
						steps: this.getSteps(index),
						probability: this.getProbability(index),
						originalIndex: index
					}
				})
			},
			maxValue() {
				return this.payouts.reduce((c, p) => Math.max(c, p.max), 0)
			},
			maxProbability() {
				return this.payoutWithProbability.reduce((c, p) => Math.max(c, p.probability), 0)
			}
		}
	}
</script>
