<template>
	<div>
		<h6>Symbol</h6>
		<div v-for="(symbol, index) in model.rules.symbols" class="my-2">
			<div class="d-inline-flex">
				<div class="flex-grow-1">
					<symbol-switcher :disabled="disabled" v-model="model.rules.symbols[index]" :config="config"></symbol-switcher>
				</div>
				<button tabindex="-1" class="btn" @click="model.rules.symbols.splice(index, 1)"><i
						class="fa fa-trash text-light"></i></button>
			</div>
		</div>
		<button class="btn btn-outline-primary text-small btn-sm mt-3" @click="model.rules.symbols.push('1')">Add symbol <i class="fa fa-plus"></i></button>
	</div>
</template>

<script>
	import SymbolSwitcher from "../../../symbols/symbol-switcher";

	export default {
		name: "until-symbol",
		components: {SymbolSwitcher},
		props: {
			value: {
				type: Object
			},
			config: {
				type: Object
			},
			disabled: {
				default: false,
				type: Boolean
			}
		},
		computed: {
			model: {
				get() {
					return this.value
				},
				set(value) {
					this.$emit("input", value)
				}
			}
		}
	}
</script>

<style scoped>

</style>
