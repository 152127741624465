<template>
	<div>
		<h6>Game type</h6>
		<select :disabled="disabled" class="form-control" style="width: auto" v-model="model.rules.type">
			<option :value="option.value" v-for="option in options" v-text="option.name"></option>
		</select>
	</div>
</template>

<script>
	export default {
		name: "game-type",
		props: {
			value: {
				type: Object
			},
			config: {
				type: Object
			},
			disabled: {
				default: false,
				type: Boolean
			}
		},
		computed: {
			model: {
				get() {
					return this.value
				},
				set(value) {
					this.$emit("input", value)
				}
			},
			options: () => [
				{
					value: 'spin',
					name: 'Normal spin'
				},
				{
					value: 'freespin',
					name: 'Freespin'
				},
			]
		}
	}
</script>

<style scoped>

</style>
