<template>
	<nav class="navbar navbar-expand-lg navbar-light bg-light">
		<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
				aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
			<span class="navbar-toggler-icon"></span>
		</button>
		<div class="collapse navbar-collapse" id="navbarNav">
			<!--
			<a class="navbar-brand" href="#" v-text="`${project.Name} - ${projectConfig.Name}`"></a>
			-->
			<ul class="navbar-nav">
				<li class="nav-item" v-if="this.canRead('projects_config')">
					<router-link :to="{
						name: 'projects-editor-uuid-cuuid-settings',
						params: {
							uuid: project.Uuid,
							cuuid: projectConfig.Uuid
						}
					}" class="nav-link">Settings</router-link>
				</li>
				<li class="nav-item" v-if="this.canRead('simulator')">
					<router-link :to="{
						name: 'projects-editor-uuid-cuuid-tests-index',
						params: {
							uuid: project.Uuid,
							cuuid: projectConfig.Uuid
						}
					}" class="nav-link">Tests</router-link>
				</li>
				<li class="nav-item" v-if="this.canRead('spinners')">
					<router-link :to="{
						name: 'projects-editor-uuid-cuuid-config-index',
						params: {
							uuid: project.Uuid,
							cuuid: projectConfig.Uuid
						}
					}" class="nav-link">Configuration</router-link>
				</li>
			</ul>
		</div>
		<slot></slot>
	</nav>
</template>

<script>
	export default {
		props: {
			project: {
				type: Object
			},
			projectConfig: {
				type: Object
			}
		}
	}
</script>
