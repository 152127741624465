<template>
	<div>
		<div class="bg-light border p-3 my-2" v-for="(scatter, index) in scatters">
			<hr v-if="index > 0">
			<div class="d-flex align-items-center">
				<div>
					<div class="text-center">
						<h5>Symbol</h5>
						<symbol-switcher :config="config" :disabled="disabled" v-model="scatter.symbol"></symbol-switcher>
						<button tabIndex="-1" class="btn" @click="removeScatter(index)" v-if="!disabled">
							<i class="fa fa-trash text-light"></i>
						</button>
					</div>
				</div>
				<div class="text-center">
					<h5>Multipliers</h5>
					<div class="ml-2 row d-flex flex-fill text-center">
						<div class="col" v-for="(prize, index) in scatter.multipliers">
							<h5 v-text="`x${index}`"></h5>
							<prize :config="config" :disabled="disabled" class="bg-white border" v-model="scatter.multipliers[index]"></prize>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="text-center w-100 mt-5" v-if="!disabled">
			<button class="btn btn-primary" @click="addScatter">ADD SCATTER SYMBOL</button>
		</div>
	</div>
</template>

<script>
	import SymbolSwitcher from "../symbols/symbol-switcher";
	import Prize from "../prize/index";

	export default {
		components: {SymbolSwitcher, Prize},
		props: {
			disabled: {
				type: Boolean,
				default: false,
			},
			value: {
				type: Array
			},
			config: {
				type: Object
			}
		},
		methods: {
			addScatter() {
				let scatter = {
					symbol: "1",
					multipliers: {}
				}

				this.reels.forEach((r, index) => {
					scatter.multipliers[index + 1] = null
				})

				this.scatters.push(scatter)
			},
			removeScatter(index) {
				this.scatters.splice(index, 1)
			}
		},
		computed: {
			scatters: {
				get() {
					return this.value
				},
				set(scatters) {
					this.$emit("input", scatters)
				}
			},
			reels() {
				return this.config.reels
			},
			extraCurrencies() {
				return this.config.editorConfig.extraCurrencies
			},
		}
	}
</script>
