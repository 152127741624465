<template>
	<div class="mt-2">

		<div class="d-flex align-items-center" v-for="(wild, index) in wilds">
			<symbol-switcher :config="config" :disabled="disabled" class="my-3" v-model="wilds[index]"></symbol-switcher>
			<div v-if="!disabled">
				<button tabIndex="-1" class="btn" @click="removeWild(index)">
					<i class="fa fa-trash text-light"></i>
				</button>
			</div>
		</div>

		<button class="mt-4 btn btn-dark" @click="addWild" v-if="!disabled">ADD WILD SYMBOL</button>
	</div>
</template>

<script>
import SymbolSwitcher from "./symbols/symbol-switcher";
import TwoWayModel from "../../mixins/two-way-model";
import Configurable from "../../mixins/configurable";
import CanBeDisabled from "../../mixins/can-be-disabled";

export default {
	components: {SymbolSwitcher},
	mixins: [TwoWayModel, Configurable, CanBeDisabled],
	methods: {
		addWild() {
			this.wilds.push("1")
		},
		removeWild(index) {
			this.wilds.splice(index, 1)
		}
	},
	computed: {
		wilds: {
			get() {
				return this.model.paytable.wilds
			},
			set(wilds) {
				this.model.paytable.wilds = wilds
			}
		}
	}
}
</script>
