<template>
	<div>
		<div class="d-flex align-items-center justify-content-end" v-for="(symbol, index) in reel">
			<div class="mr-2">
				<div class="text-small text-muted">{{ index }}</div>
			</div>
			<div class="mr-2">
				<input type="checkbox" class="form-check" v-model="model" :value="index">
			</div>
			<symbol-switcher :symbol="symbol" :config="config" :disabled="true" :showButtons="false"
											 :compact="true" :value="symbol"
											 class="animated"
											 :class="{'hidden': highlight !== null && highlight !== symbol, 'highlight': highlight === symbol}"></symbol-switcher>
		</div>
	</div>
</template>

<script>
import SymbolIcon from "../symbols/symbol-icon";
import SymbolSwitcher from "../symbols/symbol-switcher";

export default {
	name: "reel-positions-selector",
	components: {SymbolSwitcher, SymbolIcon},
	props: {
		value: {},
		reel: {},
		config: {},
		highlight: {}
	},
	mounted() {
	},
	computed: {
		model: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit("input", value.slice().sort((a, b) => a > b ? 1 : -1))
			}
		}
	}
}
</script>

<style scoped>
.hidden {
	opacity: 0.2;
}

.highlight {
	opacity: 1;
}
</style>
