<template>
	<div class="d-inline-flex align-items-center">
		<div class="d-block">
			<grid :config="computedConfig" class="pb-1" :disabled="disabled" :classes="offset.classes"
				  :labels="offset.labels"
				  v-for="(offset, index) in allOffsets" :key="index"></grid>
		</div>
		<multiplier v-model="model" :min="1" :max="config.reels.length-1" :disabled="disabled"></multiplier>
	</div>
</template>

<script>
	import Multiplier from "../prize/multiplier";
	import Grid from "../paylines/grid";

	export default {
		name: "inline-offset",
		components: {Grid, Multiplier},
		props: {
			value: {
				type: Number,
				default: 1
			},
			config: Object,
			disabled: {
				type: Boolean,
				default: false
			},
			symbols: {
				type: Array,
				default: () => []
			}
		},
		computed: {
			allOffsets() {
				let offsets = []

				const reels = this.config.reels.length

				for (let i = 0; i < reels; i++) {
					let classes = {}
					let labels = {}

					if (i + this.model < reels) {
						classes[i] = `symbol symbol-${this.symbols[0]}`
						classes[i + this.model] = `symbol symbol-${this.symbols[1]}`

						labels[i] = this.symbols[0]
						labels[i + this.model] = this.symbols[1]

					offsets.push({
						offset: [i + this.model, 0],
						classes: classes,
						labels: labels
					})
					}
				}

				return offsets
			},
			computedConfig() {
				return {
					...this.config,
					visibleLines: "1"
				}
			},
			model: {
				get() {
					return this.value
				},
				set(_val) {
					this.$emit("input", _val)
				}
			}
		}
	}
</script>

<style scoped>

</style>
