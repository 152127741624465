<template>
	<div>
		<h5>Tumbling multiplier</h5>

		<table class="table">
			<thead>
			<tr>
				<th>Tumbling #</th>
				<th>Multiplier</th>
				<th></th>
			</tr>
			</thead>
			<tbody>
			<tr v-for="(multiplier, index) in model.prizes">
				<td>
					<template v-if="index === 0">
						First tumble
					</template>
					<template v-else>
						Tumble #{{ index + 1 }}
					</template>
					<template v-if="index === model.prizes.length - 1">
						and more...
					</template>
				</td>
				<td>
					<multiplier v-model="model.prizes[index].multiplier"></multiplier>
				</td>
				<td>
					<button :disabled="disabled" class="btn btn-outline-danger text-small" @click="removeMultiplier(index)">
						Remove
					</button>
				</td>
			</tr>
			</tbody>
			<tfoot>
			<tr>
				<td colspan="3">
					<button :disabled="disabled" class="btn btn-primary" @click="addMultiplier">Add multiplier</button>
				</td>
			</tr>
			</tfoot>
		</table>
	</div>
</template>

<script>
import Multiplier from "../../prize/multiplier";

export default {
	name: "tumbling",
	components: {Multiplier},

	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		value: {
			type: Object
		},
		config: {
			type: Object
		}
	},
	methods: {
		addMultiplier() {
			this.model.prizes.push({
				multiplier: 2
			})
		},
		removeMultiplier(index) {
			this.model.prizes.splice(index, 1)
		}
	},
	computed: {
		model: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit("input", value)
			}
		}
	}
}
</script>

<style scoped>

</style>
