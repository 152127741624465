<script>
export default {
	name: "can-be-disabled",
	props: {
		disabled: {
			default: false,
			type: Boolean
		}
	}
}
</script>
