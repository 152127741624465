<template>
	<div>
		<h6>Symbols in the same row</h6>
		<div>
			<div>
				<div>
					<label class="text-small">Matching symbols:</label>
				</div>
				<multiplier v-model="model.rules.threshold" :min="2" :max="config.reels.length"></multiplier>
			</div>
			<div>
				<div>
					<label class="text-small">Valid reels:</label>
				</div>
				<reel-selector :config="config" v-model="model.rules.reels" :disabled="disabled"></reel-selector>
			</div>
			<div class="text-small">To trigger this condition player needs {{ model.rules.threshold }}{{config.reels.length > model.rules.threshold ? '+' : ''}} equal symbols on same row, only on reel {{model.rules.reels.map(r => r+1).join(", ")}}.</div>
		</div>
	</div>
</template>

<script>
import Multiplier from "../../../prize/multiplier";
import ReelSelector from "@/components/ui/ReelSelector";

export default {
	name: "row",
	components: {ReelSelector, Multiplier},
	props: {
		value: {
			type: Object
		},
		config: {
			type: Object
		},
		disabled: {
			default: false,
			type: Boolean
		}
	},
	computed: {
		model: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit("input", value)
			}
		}
	}
}
</script>

