<template>
	<div class="my-3">
		<div v-for="(bonus, index) in bonuses" :key="bonus.name">
			<div class="mr-2 d-inline-flex align-items-center">
				<span class="font-weight-bold text-uppercase" v-text="bonus.name"></span>
				<button class="ml-3 btn btn-sm text-small btn-outline-danger" @click="removeFeature(index)"
								v-if="!disabled">REMOVE
					BONUS
				</button>
			</div>

			<div class="d-flex align-items-center py-3">
				<div class="text-small mr-2">
					<span class="mr-1">Type</span>
					<span class="font-weight-bold text-uppercase" v-text="bonus.type"></span>
				</div>


			</div>
			<div class="border p-3">
				<h5>Name</h5>
				<input :disabled="disabled" type="text" class="w-25 form-control"
							 v-model="bonuses[index].name">
				<hr>
				<div :disabled="disabled" :key="bonus.name" :config="config" :is="bonus.type"
						 v-model="bonuses[index].options"></div>
			</div>
			<hr>
		</div>

		<template v-if="!disabled">
			<h4>Add new bonus</h4>
			<div class="d-flex flex-wrap">
				<div class="card m-2 bonus-card" v-for="bonus in availableBonuses">
					<div class="card-header" v-text="bonus.name"></div>
					<div class="card-body p-2 py-4 text-dark text-small" v-text="bonus.description"></div>
					<div class="card-footer p-0 border-0">
						<button class="btn btn-primary border-0 w-100" @click="addBonus(bonus)">ADD BONUS</button>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import TwoWayModel from "../../mixins/two-way-model";
import Configurable from "../../mixins/configurable";
import CanBeDisabled from "../../mixins/can-be-disabled";

export default {
	components: {
		Pick: () => import("./bonuses/pick"),
		MiniSlot: () => import("./bonuses/mini-slot"),
	},
	mixins: [TwoWayModel, Configurable, CanBeDisabled],
	methods: {
		addBonus(bonus) {
			let bonus_obj = {
				name: `${bonus.name} ${this.bonuses.length + 1}`,
				type: bonus.type,
				options: null
			}
			this.$nextTick(() => this.bonuses.push(bonus_obj))
		},
		removeFeature(index) {
			this.bonuses.splice(index, 1)
		}
	},
	computed: {
		bonuses: {
			get() {
				return this.model.bonuses
			},
			set(bonuses) {
				this.model.bonuses = bonuses
			}
		},
		extraCurrencies() {
			return this.config.editorConfig.extraCurrencies
		},
		availableBonuses() {
			return [
				{
					name: "Pick Bonus",
					type: "pick",
					description: "Pick a random prize after user action"
				},
				{
					name: "Baby Slot Bonus",
					type: "mini-slot",
					description: "User will access to a special mini-slot machine game inside the bonus"
				}
			]
		}
	}
}
</script>

<style scoped lang="scss">
.bonus-card {
	width: 200px;
}
</style>
