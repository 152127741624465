<template>
	<div class="d-flex">
		<div class="mr-3 d-flex justify-content-center text-center">
			<div>
				<div>
					<img :src="modifierDetails.icon" class="img-fluid mt-2" style="width: 32px; height: 32px;">
				</div>

				<div class="mt-3 handle cursor-grabbable" v-if="!disabled">
					<i class="fa fa-grip-lines"></i>
					<div class="text-small text-muted" style="font-size: 6px;">DRAG ME</div>
				</div>
			</div>
		</div>
		<div class="flex-grow-1">
			<h6 v-text="modifierDetails.name"></h6>
			<div class="border p-3">
				<h6 class="my-0">{{ modifierDetails.name }} options</h6>
				<div class="my-3 text-small" :disabled="disabled" :config="config" :is="modifierDetails.type"
						 v-model="model.configuration"></div>
			</div>

			<div class="p-3 border my-2">
				<h6 class="my-0">Trigger conditions</h6>
				<div class="text-small text-muted">Every condition need to be satisfied for trigger the modifier</div>
				<conditions :disabled="disabled" class="mt-2" :config="config" v-model="model.configuration.startConditions"
										:conditions-list="startConditions"></conditions>
			</div>

			<div class="p-3 border my-2">
				<h6 class="m-0">Final conditions</h6>
				<div class="text-small text-muted">Only one of these conditions need to be satisfied to stop the
					modifier
				</div>
				<conditions :disabled="disabled" class="mt-2" :config="config" v-model="model.configuration.endConditions"
										:conditions-list="endConditions"></conditions>
			</div>
		</div>
	</div>
</template>

<script>
import modifiers from './modifiers'
import Sticky from './types/sticky'
import Shifting from './types/shifting'
import Expanding from './types/expanding'

import startConditions from './conditions/start-conditions-list'
import endConditions from './conditions/end-conditions-list'
import Conditions from "./conditions/conditions-list";

export default {
	name: "symbol-modifier",
	components: {Conditions, Sticky, Shifting, Expanding},
	props: {
		value: {
			type: Object,
			default: () => ({})
		},
		config: {
			type: Object
		},
		disabled: {
			default: false,
			type: Boolean
		}

	},
	data: () => ({startConditions, endConditions}),
	computed: {
		model: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit("input", value)
			}
		},
		modifierDetails() {
			return modifiers.filter(m => m.type === this.value.type)[0]
		}
	}
}
</script>

<style scoped>

</style>
