<template>
	<div class="mt-4">
		<div class="form-group">
			<input :disabled="disabled" v-model="gamble.enabled" type="checkbox" id="enabled">
			<label class="ml-2"
						 for="enabled">Gamble enabled</label>
		</div>
		<div class="form-group">
			<div class="font-weight-bold">
				Results valid:
			</div>
			<div v-for="type in spinTypes">
				<input :disabled="disabled" v-model="gamble.enabled_stages" :value="type.value" type="checkbox" :id="type.name">
				<label class="ml-2"
							 :for="type.name" v-text="type.name"></label>
			</div>
		</div>
		<div class="form-group">
			Number of gamble phases:<br/>
			<multiplier :disabled="disabled" v-model="gamble.limit" :min="0" :max="100" :step="1"></multiplier>
		</div>
		<div class="form-group">
			Choices:<br/>
			<div v-for="choice in validChoices">
				<input :disabled="disabled" v-model="gamble.choices" :value="choice.value" type="checkbox" :id="choice.name">
				<label class="ml-2"
							 :for="choice.name" v-text="choice.name"></label>
			</div>
		</div>
		<div class="form-group">
			Bet limit:<br/>
			<multiplier :disabled="disabled" v-model="gamble.bet_limit"></multiplier>
		</div>
	</div>
</template>

<script>
import Multiplier from "./prize/multiplier";
import TwoWayModel from "../../mixins/two-way-model";
import CanBeDisabled from "../../mixins/can-be-disabled";

export default {
	name: "gamble",
	components: {Multiplier},
	mixins: [TwoWayModel, CanBeDisabled],
	mounted() {
		if (!Array.isArray(this.gamble.choices)) {
			this.$nextTick(() => this.gamble.choices = [this.gamble.choices])
		}

		if (!this.gamble.bet_limit) {
			this.$nextTick(() => this.gamble.bet_limit = 150)
		}
	},
	computed: {
		spinTypes() {
			return [
				{
					name: 'Spin',
					value: 'N'
				},
				{
					name: 'Freespin',
					value: 'F'
				},
				{
					name: 'Bonus',
					value: 'B'
				}
			]
		},
		validChoices() {
			return [
				{
					name: 'x2',
					value: 2
				},
				{
					name: 'x4',
					value: 4
				}

			]
		},
		gamble: {
			get() {
				return this.model.gamble
			},
			set(_val) {
				this.model.gamble = _val
			}
		}
	}
}
</script>

<style scoped>

</style>
