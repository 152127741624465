export default [
	{
		type: 'reel',
		name: 'Valid reels',
		default: () => ({
			reels: []
		})
	},
	{
		type: 'row',
		name: 'Row number',
		default: () => ({
			rows: []
		})
	},
	/*
	{
		type: 'in-a-row',
		name: 'Symbols in same row',
		default: () => ({
			threshold: 2,
			reels: []
		})
	},
	 */
	{
		type: 'game-type',
		name: 'Type of spin',
		default: () => ({
			type: 'freespin'
		})
	},
	{
		type: 'until-symbol',
		name: 'Until this symbol appear',
		default: () => ({
			symbols: ["1"]
		})
	},
	{
		type: 'number-of-symbols',
		name: 'Occurrences of the same symbol',
		default: () => ({
			occurrences: 3
		})
	},
]
