<template>
	<div class="row">
		<payouts class="col-4" :disabled="disabled" :show-multiplier="true" :show-extra="false" :show-freespins="false" :config="config"
						 v-model="model.payouts" :show-strategy="false"/>
		<payouts-summary class="col-8" :payouts="model.payouts" :config="config"></payouts-summary>
	</div>
</template>

<script>
import Payouts from "../../bonuses/payouts";
import PayoutsSummary from "../../bonuses/payouts-summary";

export default {
	name: "rng",
	components: {PayoutsSummary, Payouts},
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		value: {
			type: Object
		},
		config: {
			type: Object
		}
	},
	computed: {
		model: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit("input", value)
			}
		}
	}
}
</script>

<style scoped>

</style>
