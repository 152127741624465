<template>
	<div>
		<div class="row d-flex align-items-center">
			<div class="col text-center">
				<div class="text-nowrap text-small font-weight-bold">First symbol</div>
				<symbol-switcher :config="config" :disabled="disabled" class="d-inline-flex" v-model="options.first"></symbol-switcher>
			</div>
			<div class="col text-center">
				<div class="text-nowrap text-small font-weight-bold">Second symbol</div>
				<symbol-switcher :config="config" :disabled="disabled" class="d-inline-flex" v-model="options.second"></symbol-switcher>
			</div>
			<div class="col text-center">
				<div class="text-nowrap text-small font-weight-bold">Offset to second symbol</div>
				<offset :config="config" :disabled="disabled" class="d-inline-block" :symbols="[options.first, options.second]"
						v-model="options.offset"></offset>
			</div>
			<div class="col text-center">
				<div class="text-nowrap text-small font-weight-bold">Prize</div>
				<div class="d-inline-flex">
					<prize :config="config" :disabled="disabled" class="bg-light border" v-model="options.prize"></prize>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import SymbolSwitcher from "../symbols/symbol-switcher";
	import Offset from "./offset";
	import Prize from "../prize/index";

	export default {
		components: {Prize, Offset, SymbolSwitcher},
		props: {
			disabled: {
				type: Boolean,
				default: false,
			},
			value: {
				type: Object
			},
			config: {
				type: Object
			}
		},
		computed: {
			options: {
				get() {
					return this.value
				},
				set(options) {
					this.$emit("input", options)
				}
			}
		}
	}
</script>
