<template>
	<div>
		<h6>Number of respins</h6>
		<multiplier :disabled="disabled" v-model="model.rules.respins" :min="0" :max="100" :step="1"></multiplier>
	</div>
</template>

<script>
    import Multiplier from "../../../prize/multiplier";
	export default {
        name: "respins",
		components: {Multiplier},
		props: {
			value: {
				type: Object
			},
			config: {
				type: Object
			},
			disabled: {
				default: false,
				type: Boolean
			}
		},
		computed: {
			model: {
				get() {
					return this.value
				},
				set(value) {
					this.$emit("input", value)
				}
			}
		}
    }
</script>

<style scoped>

</style>
