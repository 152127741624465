<template>
	<div v-if="buyFeatures !== undefined">
		<div class="row">
			<div class="col">
				<label>Features buy options</label>
				<div class="text-muted">With this option you can allow players to buy features, like free spins or bonus
				</div>
			</div>
			<div class="col">
				<label>Buy features enabled?</label>
				<div>
					<input type="checkbox" v-model="buyFeatures.enabled"> <span v-if="buyFeatures.enabled"
																																			class="text-success"
																																			:disabled="disabled">ENABLED</span>
				</div>
			</div>
		</div>
		<div class="d-flex mt-4">
			<div class="flex-grow-1">
				<div v-for="(tier, index) in buyFeatures.tiers">
					<div class="d-flex">
						<tier class="flex-grow-1" v-model="buyFeatures.tiers[index]" :config="config"
									:disabled="disabled || !buyFeatures.enabled"/>
						<button class="ml-2 btn btn-outline-danger py-0"
										@click="removeFeature(index)" :disabled="disabled || !buyFeatures.enabled">
							<i class="fa fa-trash"></i>
						</button>
						<div class="ml-2">
						</div>
					</div>
					<hr>
				</div>


				<div class="mt-3">
					<button class="btn btn-primary" @click="addFeature" :disabled="disabled || !buyFeatures.enabled">Add buy
						feature
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Multiplier from "./prize/multiplier";
import SimpleModal from "../layout/simple-modal";
import Reel from "./modifiers/conditions/editor/reel";
import ReelPositionsSelector from "./reels/reel-positions-selector";
import SymbolSwitcher from "./symbols/symbol-switcher";
import String from '@/service/strings'
import TwoWayModel from "../../mixins/two-way-model";
import Tier from "./buy-feature/tier";
import Configurable from "../../mixins/configurable";
import CanBeDisabled from "../../mixins/can-be-disabled";

export default {
	name: 'buy-features',
	components: {Tier, SymbolSwitcher, ReelPositionsSelector, Reel, SimpleModal, Multiplier},
	mixins: [TwoWayModel, Configurable, CanBeDisabled],
	data: () => ({
		rngModal: false,
		currentIndex: null,
		symbolToHighlight: null
	}),
	methods: {
		addFeature() {
			this.buyFeatures.tiers.push({
				id: String.getRandom(4),
				priceMultiplier: 1,
				prizes: []
				//feature: 'freespin',
				//RNGs: this.getDefaultStops()
			})
		},
		getDefaultStops() {
			return Array.from({length: this.config.reels.length}, () => ([]))
		},
		autoSelect() {

			this.buyFeatures.tiers[this.currentIndex].RNGs = this.getDefaultStops()
			const rows = this.config.visibleLines

			for (let i = 0; i < this.config.reels.length; i++) {
				const reel = this.config.reels[i]

				let selection = []

				for (let x = 0; x < reel.length; x++) {
					let check = reel[x]
					if (typeof reel[x] === 'object') {
						check = reel[x].id
					}

					if (check === this.symbolToHighlight) {
						for (let l = 0; l < rows; l++) {
							let index = (reel.length + x - rows + 1 + l) % reel.length

							selection.push(index)
						}
					}
				}

				this.buyFeatures.tiers[this.currentIndex].RNGs[i] = selection
			}
		},
		toggleAllByReel(reelIndex) {

			const fullReel = Array.from({length: this.config.reels[reelIndex].length}, (item, index) => index)

			if (JSON.stringify(this.buyFeatures.tiers[this.currentIndex].RNGs[reelIndex]) === JSON.stringify(fullReel)) {
				this.buyFeatures.tiers[this.currentIndex].RNGs[reelIndex] = []
			} else {
				this.buyFeatures.tiers[this.currentIndex].RNGs[reelIndex] = fullReel
			}
			this.$nextTick(() => this.$forceUpdate())
		},
		removeFeature(index) {
			this.buyFeatures.tiers.splice(index, 1)
		},
		editRNGs(index) {
			this.currentIndex = index
		}
	},
	computed: {
		buyFeatures: {
			get() {
				const {buyFeatures} = this.model
				return buyFeatures
			},
			set(value) {
				this.model.buyFeatures = value
			}
		}
	}
}
</script>
