<template>
	<div>
		<div class="d-inline-flex align-items-center m-1 mr-3" v-for="direction in directions">
			<input :disabled="disabled" :id="`${_uid}-shifting-${direction.value}`" type="radio" v-model="model.direction"
						 :value="direction.value"
						 class="mr-2"> <label class="m-0" :for="`${_uid}-shifting-${direction.value}`"
																	v-text="direction.name"></label>
		</div>

		<grid :config="config" :classes="classes" :disabled="true"></grid>

	</div>
</template>

<script>
import Grid from "../../paylines/grid";

export default {
	name: "expanding",
	components: {Grid},
	props: {
		value: {
			type: Object
		},
		config: {
			type: Object
		},
		disabled: {
			default: false,
			type: Boolean
		}
	},
	methods: {
		getIndex(column, row) {
			return column + row * this.config.reels.length
		},
	},
	computed: {
		model: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit("input", value)
			}
		},
		directions: () => [
			{
				value: 'vertical',
				name: 'Vertical'
			},
			{
				value: 'horizontal',
				name: 'Horizontal'
			},
			{
				value: 'both',
				name: 'Vertical + Horizontal'
			},
		],
		classes() {
			let tmp = {}

			if (['horizontal', 'both'].includes(this.model.direction)) {
				for (let reel_id = 0; reel_id < this.config.reels.length; reel_id++) {
					tmp[this.getIndex(reel_id, Math.floor(this.config.visibleLines / 2))] = "bg-primary"
				}
			}

			if (['vertical', 'both'].includes(this.model.direction)) {
				for (let row_id = 0; row_id < this.config.visibleLines; row_id++) {
					tmp[this.getIndex(Math.floor(this.config.reels.length / 2), row_id)] = "bg-primary"
				}
			}

			tmp[this.getIndex(Math.floor(this.config.reels.length / 2), Math.floor(this.config.visibleLines / 2))] = "bg-success"

			return tmp
		},
	}
}
</script>

<style scoped>

</style>
