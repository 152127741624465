<template>
	<div>
		<textarea rows="4" class="form-control text-monospace" v-model="tmpValue"
							@keydown.enter.prevent.stop="importReel" @keyup="modified = true"
							:disabled="disabled"></textarea>
		<div class="mt-2" v-if="tmpValue.length > 0 && modified">
			<div class="alert alert-danger" v-if="!valid">
				<h4>Check symbols syntax</h4>
				<div>int, int, int, ...</div>
				<div>Symbols must be one of {{ symbols.map(s => s.id) }} or {{ symbols.map(s => s.name) }}</div>
			</div>

			<button class="btn btn-success mb-3" @click="importReel" v-if="valid && disabled">
				IMPORT
			</button>

			<div class="clearfix text-light" v-text="`${parsedReel.length} symbols`"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'reel-importer',
	data() {
		return {
			tmpValue: this.value.join(", "),
			modified: false
		}
	},
	props: {
		value: {
			type: Array,
			default: [],
		},
		config: {
			type: Object
		},
		acceptNulls: {
			type: Boolean,
			default: false
		},
		disabled: {
			default: false,
			type: Boolean
		}
	},
	methods: {
		importReel() {
			this.$emit("input", this.parsedReel.map(s => s ? `${s}` : null))
		},
		findCorrispondence(symbolString) {
			if (!isNaN(parseInt(symbolString))) {
				return parseInt(symbolString);
			}

			let foundElement = this.config.symbols.find(s => s.name === symbolString)
			if (foundElement !== undefined) {
				return foundElement.id
			} else {
				return null;
			}
		}
	},
	watch: {
		value() {
			this.tmpValue = this.value.join(", ")
			this.modified = false
		}
	},
	computed: {
		symbols() {
			return this.config.symbols
		},
		parsedReel() {
			let symbols = this.tmpValue.replaceAll("\n", ",").split(",")

			return symbols.map(this.findCorrispondence)
		},
		valid() {
			return this.parsedReel.every(s => s !== null)
		}
	}
}
</script>
