<template>
	<div class="bg-light p-4">
		No options available
	</div>
</template>

<script>
import Conditions from "../conditions/conditions-list";

export default {
	name: "sticky",
	components: {Conditions},
	props: {
		value: {
			type: Object
		},
		config: {
			type: Object
		},
		disabled: {
			default: false,
			type: Boolean
		}
	},
	computed: {
		model: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit("input", value)
			}
		}
	}
}
</script>

<style scoped>

</style>
