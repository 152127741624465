<template>
	<div v-if="payout" class="d-flex flex-fill bg-light border">
		<div class="p-3">
			<h5>Limit</h5>
			<multiplier :disabled="disabled" v-model="payout.max" :min="0"></multiplier>
		</div>
		<div class="p-3">
			<h5>Prize</h5>
			<prize :show-freespins="showFreespins" :show-extra="showExtra" :show-multiplier="showMultiplier"
						 :show-strategy="showStrategy" :config="config" :disabled="disabled" class="bg-white border"
						 v-model="payout.prize"></prize>
		</div>
	</div>
</template>

<script>
import Multiplier from "../prize/multiplier";
import Prize from "../prize/index";

export default {
	components: {Multiplier, Prize},
	props: {
		value: {
			type: Object
		},
		disabled: {
			type: Boolean,
			default: false
		},
		config: {
			type: Object
		},
		showExtra: {
			type: Boolean,
			default: true
		},
		showMultiplier: {
			type: Boolean,
			default: true
		},
		showFreespins: {
			type: Boolean,
			default: true
		},
		showStrategy: {
			type: Boolean,
			default: true
		},
	},
	mounted() {
		if (this.payout === null) {
			this.payout = {
				max: 0,
				prize: null
			}
		}
	},
	computed: {
		payout: {
			get() {
				return this.value
			},
			set(payout) {
				this.$emit("input", payout)
			}
		}
	}
}
</script>
