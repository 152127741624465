<template>
  <div>
    <draggable class="nav nav-tabs d-inline-flex align-items-center" tag="ul" v-model="model" group="model"
               handle=".reel" :disabled="disabled">
      <li class="nav-item reel" v-for="(reel, index) in model">
        <div class="cursor-pointer nav-link text-center" :class="{active: index === currentReel}"
             @click="currentReel = index">
          <div>Reel {{ index + 1 }}</div>
          <div class="text-small"
               :class="{'text-danger': reel.symbols.length === 0, 'text-primary': reel.symbols.length > 0}"
               v-text="`${reel.symbols.length} symbols`"></div>
        </div>
      </li>
      <li class="nav-item add-button" v-if="!disabled">
        <div class="cursor-pointer nav-link" @click="addReel">
          <i class="fa fa-plus"/>
        </div>
      </li>
    </draggable>

    <div class="p-2 bg-light text-center" v-if="model.length === 0">
      No overlay reels
    </div>

    <overlay-reel :config="config" v-model="model[currentReel]"
                  v-if="model.length > 0 && model[currentReel] !== undefined"></overlay-reel>

    <div class="my-3" v-if="!disabled">
      <button class="btn btn-danger" @click="model.splice(currentReel, 1)">DELETE REEL {{ currentReel + 1 }}</button>
    </div>
  </div>
</template>

<script>
import OverlayReel from "./overlay-reel";

export default {
  name: "overlay-reels",
  components: {OverlayReel},
  props: {
    value: {
      type: Array,
      default: () => []
    },
    config: {
      type: Object
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      currentReel: 0
    }
  },
  methods: {
    addReel() {
      this.model.push({
        offset: 0,
        symbols: []
      })
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(_val) {
        this.$emit("input", _val)
        this.$nextTick(() => this.$forceUpdate())
      }
    }
  }
}
</script>

<style scoped>

</style>
