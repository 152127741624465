<template>
	<div class="form-group">
		<textarea
				class="form-control text-monospace importer text-small bg-dark text-white p-3"
				:class="{valid: jsonIsValid}"
				@input="refreshTextareaHeigth" v-model="localValue" ref="jsonArea">
		</textarea>
		<button class="btn btn-success mt-3" @click="importJSON" :disabled="!jsonIsValid || !isModified"
				v-if="!disabled">IMPORT
		</button>
		<a :href="downloadableJSON" :download="`${identifier.toLowerCase()}.json`" class="btn btn-primary ml-2 mt-3"
		   :disabled="!jsonIsValid">DOWNLOAD JSON</a>
	</div>
</template>

<script>
	export default {
		props: {
			value: {
				type: Object,
				default: {}
			},
			identifier: {
				type: String,
				default: ""
			},
			disabled: {
				default: false,
				type: Boolean
			}
		},
		data() {
			return {
				localValue: null
			}
		},
		mounted() {
			this.localValue = JSON.stringify(this.value)
			this.$nextTick(this.refreshTextareaHeigth)
		},
		watch: {
			value(val) {
				this.localValue = JSON.stringify(val)
			}
		},
		methods: {
			refreshTextareaHeigth() {
				this.$refs.jsonArea.style.height = "auto"
				this.$refs.jsonArea.style.height = `${this.$refs.jsonArea.scrollHeight}px`;
			},
			importJSON() {
				if (this.jsonIsValid) {
					this.$emit("input", JSON.parse(this.localValue))
					alert("Import success")
				}
			}
		},
		computed: {
			downloadableJSON() {
				return "data:text/json;charset=utf-8," + this.localValue
			},
			downloadName() {

			},
			isModified() {
				return JSON.stringify(this.value) !== this.localValue
			},
			jsonIsValid() {
				try {
					return JSON.parse(this.localValue) !== undefined
				} catch (e) {
					return false
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	@import "@/assets/style/_palette";

	.importer {
		border: 0.4rem solid $color-danger;

		&.valid {
			border-color: $color-success;
		}
	}
</style>
