<template>
	<div v-if="model.prize">
		<h6>Multiplier</h6>
		<multiplier :disabled="disabled" v-model="model.prize.multiplier"></multiplier>
	</div>
</template>

<script>
import Prize from "../../prize";
import Multiplier from "../../prize/multiplier";

export default {
	name: "fixed",
	components: {Multiplier, Prize},
	props: {
	  disabled: {
		  type: Boolean,
		  default: false,
	  },
		value: {
			type: Object
		},
		config: {
			type: Object
		}
	},
	computed: {
		model: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit("input", value)
			}
		}
	}
}
</script>

<style scoped>

</style>
