<template>
	<div>
		<div class="d-flex">
			<h5 class="flex-grow-1">Weight</h5>
			<multiplier class="w-100" v-model="model.weight" :disabled="disabled"></multiplier>
		</div>
		<hr>
		<h5 class="flex-grow-1">Valid stops</h5>
		<div class="d-flex align-items-center">

			<table class="table">
				<thead>
				<tr>
					<th></th>
					<th v-for="(reel, index) in reels">#{{ index + 1 }}</th>
					<th></th>
				</tr>
				</thead>
				<tbody>
				<tr v-if="!model.RNGs.length">
					<td class="text-muted text-center" :colspan="reels.length + 2">
						No stops defined
					</td>
				</tr>
				<tr v-for="(rng, rngId) in model.RNGs" v-else>
					<td>{{ rngId + 1 }}</td>
					<td v-for="(reel, index) in reels">
						<div class="d-flex">
							<div class="bg-light border p-1 d-flex flex-column" style="gap: 5px">
								<symbol-switcher :value="[...reel,...reel][model.RNGs[rngId][index] + i - 1]" :config="config"
																 :disabled="true"
																 :show-buttons="false" :compact="true"
																 v-bind:key="i"
																 v-for="i in parseInt(config.visibleLines)"></symbol-switcher>
							</div>
							<multiplier :key="`${index}-${rngId}`" v-model="model.RNGs[rngId][index]" :min="0"
													:max="reels[index].length - 1" @keyup.native.enter="addRNG" v-if="!disabled"/>
						</div>
					</td>
					<td>
						<button class="btn btn-outline-danger" tabindex="-1" @click="removeRNGs(rngId)" :disabled="disabled">
							<i class="fa fa-trash"></i>
						</button>
					</td>
				</tr>
				</tbody>
				<tfoot>
				<td :colspan="reels.length + 2">
					<button class="btn btn-outline-primary w-100 btn-sm" @click="addRNG" :disabled="disabled">
						<i class="fa fa-plus"></i> Add stops
					</button>
					<button class="btn btn-info btn-sm w-100 mt-2" @click="modalOpen = true" :disabled="disabled">Import from text</button>
				</td>
				</tfoot>
			</table>

			<simple-modal v-if="modalOpen" @close="modalOpen = false">
				<p>Please paste here text copied from Excel</p>
				<textarea v-model="importText" class="form-control" rows="20"></textarea>

				<div slot="footer">
					<button type="button" class="btn btn-primary" @click="importString">Import</button>
				</div>

			</simple-modal>

		</div>
	</div>
</template>

<script>
import TwoWayModel from "../../../mixins/two-way-model";
import Multiplier from "../prize/multiplier";
import Configurable from "../../../mixins/configurable";
import SymbolSwitcher from "../symbols/symbol-switcher";
import SimpleModal from "../../layout/simple-modal";
import CanBeDisabled from "../../../mixins/can-be-disabled";

export default {
	name: "prize-editor",
	components: {SimpleModal, SymbolSwitcher, Multiplier},
	mixins: [TwoWayModel, Configurable, CanBeDisabled],
	data: () => ({
		modalOpen: false,
		importText: "",
	}),
	methods: {
		addRNG() {
			this.model.RNGs.push((new Array(this.config.reels.length)).fill(0))
		},
		removeRNGs(index) {
			this.model.RNGs.splice(index, 1)
		},
		importString() {
			const lines = this.importText.split("\n")

			let RNGs = []

			for (let i in lines) {
				const stops = lines[i].split("\t").map(v => parseInt(v))
				RNGs.push(stops)
			}

			this.$nextTick(() => {
				this.modalOpen = false
				this.model.RNGs = RNGs
			})
		}
	},
	computed: {
		reels() {
			return this.config.reels
		}
	}
}
</script>
