<template>
	<div>
		<div class="row">
			<div class="col">
				<label>Number of slot lines</label>
				<small class="ml-2 text-light">Lines involved in math</small>
				<input type="number" v-model="model.visibleLines" class="form-control" :disabled="disabled"/>
			</div>
			<div class="col">
				<label>Padding lines</label>
				<small class="ml-2 text-light">Lines sent to client, but not involved in math</small>
				<input type="number" v-model="model.paddingLines" class="form-control" :disabled="disabled"/>
			</div>
		</div>
		<div class="row mt-4">
			<div class="col">
				<label>Collectibles</label>
				<small class="ml-2 text-light">Extra points that trigger bonus</small>
				<tag-input placeholder="Mushrooms, diamonds, what do you need in your game?"
									 v-model="model.editorConfig.extraCurrencies" :disabled="disabled"></tag-input>
			</div>
			<div class="col">
				<label>Target RTP</label>
				<input type="number" v-model="model.editorConfig.targetRTP" class="form-control" :disabled="disabled"/>
			</div>
		</div>
		<div class="row mt-4">
			<div class="col">
				<label>Valid coins</label>
				<small class="ml-2 text-light">Coins valid for line bet (in cents)</small>
				<tag-input placeholder="5, 10, 20, 100" v-model="model.coins" :disabled="disabled"></tag-input>
			</div>
			<div class="col">
				<label>Valid paylines</label>
				<tag-input placeholder="10, 15, 10, 25" v-model="model.valid_paylines" :disabled="disabled"></tag-input>
			</div>
		</div>

		<div class="row mt-4">
			<div class="col">
				<label>Version</label>
				<input type="text" v-model="model.version" class="form-control" :disabled="disabled"/>
			</div>
			<div class="col">
				<label>Falling blocks enabled</label>
				<select :disabled="disabled" class="form-control" v-model="model.falling_blocks_enabled">
					<option :value="true">Enabled</option>
					<option :value="false">Not enabled</option>
				</select>
			</div>
		</div>
		<div class="row mt-4">
			<div class="col">
				<div>
					<label>Jackpot enabled slot?</label>
					<select :disabled="disabled" class="form-control" v-model="model.jackpot.enabled">
						<option :value="true">Enabled</option>
						<option :value="false">Not enabled</option>
					</select>
				</div>

				<div class="my-3"></div>

				<div>
					<label>Jackpots</label>
					<jackpot-config-model v-model="model.jackpot.jackpots"
																:disabled="!model.jackpot.enabled || disabled"></jackpot-config-model>
				</div>
			</div>
		</div>
		<div class="row mt-4">
			<div class="col">
				<label>Keep state splitted by bet per line</label>
				<select :disabled="disabled" class="form-control" v-model="model.keep_states_splitted">
					<option :value="true">Enabled</option>
					<option :value="false">Not enabled</option>
				</select>
			</div>
		</div>
	</div>
</template>

<script>
import TagInput from "@/components/ui/tag-input";
import JackpotConfigModel from "./jackpots/jackpot-config-model";
import TwoWayModel from "../../mixins/two-way-model";
import CanBeDisabled from "../../mixins/can-be-disabled";

export default {
	components: {JackpotConfigModel, TagInput},
	mixins: [TwoWayModel, CanBeDisabled],
	mounted() {
		if (this.model.editorConfig === undefined) {
			this.$nextTick(() => this.model.editorConfig = {})
		}

		if (this.model.buyFeatures === undefined) {
			this.$set(this.model, "buyFeatures", {
				enabled: false,
				tiers: []
			})
		}
	},
}
</script>
