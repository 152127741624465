<template>
	<div>
		<draggable class="nav nav-tabs d-inline-flex align-items-center" tag="ul" v-model="reels" group="reels"
				   handle=".reel" :disabled="disabled">
			<li class="nav-item reel" v-for="(reel, index) in reels">
				<div class="cursor-pointer nav-link text-center" :class="{active: index === currentReel}"
					 @click="currentReel = index">
					<div>Reel {{index+1}}</div>
					<div class="text-small"
						 :class="{'text-danger': reel.length === 0, 'text-primary': reel.length > 0}"
						 v-text="`${reel.length} symbols`"></div>
				</div>
			</li>
			<li class="nav-item add-button" v-if="!disabled">
				<div class="cursor-pointer nav-link" @click="addReel">
					<i class="fa fa-plus"/>
				</div>
			</li>
		</draggable>

		<reel-config :disabled="disabled" class="py-4" v-model="reels[currentReel]" :key="currentReel" :config="config"></reel-config>
		<button class="btn btn-danger" @click="askForDelete" v-if="!disabled">DELETE REEL</button>
	</div>
</template>

<script>
	import ReelConfig from './reel-config'
	import messageBox from '@/components/modals/confirm'

	export default {
		props: {
			value: {
				type: Array
			},
			config: {
				type: Object
			},
			disabled: {
				default: false,
				type: Boolean
			}
		},
		data() {
			return {
				currentReel: 0
			}
		},
		mounted() {
			if (this.reels.length === 0) {
				this.addReel()
			}
		},
		methods: {
			addReel() {
				this.reels.push([])
				this.currentReel = this.reels.length - 1
			},
			askForDelete() {
				messageBox({
					message: `Want to delete reel #${this.currentReel + 1}?`
				})
						.then(this.deleteReel)
			},
			deleteReel() {
				this.reels.splice(this.currentReel, 1)
				this.currentReel = 0
			}
		},
		computed: {
			reels: {
				get() {
					return this.value
				},
				set(reels) {
					this.$emit('input', reels)
				}
			}
		},
		components: {
			ReelConfig
		}
	}
</script>
